// src/api/latestBusinesses.js
import api from './index';

export const getLatestBusinesses = async () => {
    try {
        const response = await api.get('/api/latest-businesses/');
        return response.data;
    } catch (error) {
        console.error('Error fetching latest businesses:', error);
        throw error;
    }
};
