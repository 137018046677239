import React, { useState } from 'react';
import { filterByMap } from '../api/filters';
import TableComponent from './TableComponent';


const KosovoMap = ({ onMunicipalityClick }) => {
  
  const [mapResults, setMapResults] = useState([]);

  const [selectedMunicipality, setSelectedMunicipality] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = async (municipality) => {
    try {
      setLoading(true);
      setSelectedMunicipality(municipality);
      const data = await filterByMap(municipality);
      console.log("MAP DATA", data);
      setMapResults(Array.isArray(data) ? data : data.businesses || []);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      setMapResults([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="default-padding">
    <div className="container prici">
        <div className="row"
            style={{
                display: 'flex',       // Make the container a flex container
                alignItems: 'center',  // Center align items vertically
            }}
        >
            <div className="col-lg-8 offset-lg-2">
                <div className="site-heading text-center">
                    <h4>Latest Statistics</h4>
                    <h2>Kosova Map</h2>
                </div>
            </div>
            <div className="col-lg-5">
                <svg version="1.1" className="highcharts-root" style={{ fontSize: '12px', fontFamily: 'sans-serif' }}
                xmlns="http://www.w3.org/2000/svg" width="1200" height="620" viewBox="0 0 1140 620">
                <desc>Created with Highcharts 9.3.2</desc>
                <defs>
                    <clipPath id="highcharts-0wbmczz-60-">
                        <rect x="0" y="0" width="1120" height="595" fill="none"></rect>
                    </clipPath>
                    <clipPath id="highcharts-0wbmczz-61-">
                        <rect x="0" y="0" width="1120" height="595" fill="none"></rect>
                    </clipPath>
                </defs>
                <rect fill="#ffffff" className="highcharts-background" x="0" y="0" width="1140" height="620" rx="0" ry="0"></rect>
                <rect fill="none" className="highcharts-plot-background" x="10" y="10" width="1120" height="595"></rect>
                <rect fill="none" className="highcharts-plot-border" data-z-index="1" x="10" y="10" width="1120" height="595"></rect>
                <path fill="none" className="highcharts-crosshair highcharts-crosshair-thin" data-z-index="2" stroke="#999999"
                    stroke-width="0.01" style={{pointerEvents: 'none'}} visibility="hidden" d="M 0 0"></path>
                <g className="highcharts-series-group" data-z-index="3">
                   
                    <g className="highcharts-markers highcharts-series-0 highcharts-map-series highcharts-color-0 highcharts-tracker"
                        data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)" clip-path="none"
                        style={{cursor: 'pointer'}}></g>
                    <g className="highcharts-series highcharts-series-1 highcharts-map-series highcharts-color-1 highcharts-tracker"
                        data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)"
                        clip-path="url(#highcharts-0wbmczz-61-)" stroke-width="18.23529411764706" style={{cursor: 'pointer'}}>
                        <g id="map" transform="translate(344.37419354838715,540.216129032258) scale(0.05483870967741934 -0.05483870967741934)"
                            animator="1">
                            <path
                                id="municipality1"
                                d="M 4969 4576 L 5070 4733 L 5142 4871 L 5098 4969 L 5069 5184 L 5098 5381 L 5054 5518 L 4982 5694 L 4996 5871 L 5126 6028 L 5227 6028 L 5299 6106 L 5357 6224 L 5429 6146 L 5487 6028 L 5589 5970 L 5661 6048 L 5762 6068 L 5819 6186 L 5992 6245 L 6209 6344 L 6296 6188 L 6412 6090 L 6513 5973 L 6682 5969 L 6635 5886 L 6691 5847 L 6786 5819 L 6991 5826 L 7203 5798 L 7069 5503 L 6752 5492 L 6798 5352 L 6967 5230 L 7035 5103 L 6974 4993 L 6894 4916 L 6567 4904 L 6459 4783 L 6345 4640 L 6171 4736 L 6011 4814 L 5823 4853 L 5736 4813 L 5737 4480 L 5592 4421 L 5404 4459 L 5302 4518 L 5143 4439 L 5056 4537 L 4969 4576 L 4969 4576 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-priština highcharts-key-kv-7310"></path>
                            <path
                                id="municipality2" 
                                d="M 5913 3617 L 5949 3765 L 6110 3783 L 6145 3906 L 6093 4046 L 6025 4186 L 6045 4306 L 6159 4450 L 6317 4384 L 6447 4423 L 6564 4306 L 6666 4228 L 6796 4307 L 6766 4484 L 6882 4543 L 6984 4465 L 7129 4466 L 7246 4251 L 7377 4114 L 7509 3939 L 7466 3762 L 7708 3696 L 7878 3639 L 8005 3638 L 7995 3521 L 8025 3279 L 8000 3160 L 7883 3048 L 7547 2942 L 7427 2814 L 7422 2630 L 7645 2296 L 7705 2084 L 7692 2091 L 7454 2027 L 7337 2024 L 7236 2093 L 7271 2329 L 7197 2525 L 7124 2642 L 7006 2936 L 6817 2974 L 6698 3159 L 6597 3267 L 6496 3325 L 6437 3462 L 6233 3370 L 6060 3265 L 5913 3617 L 5913 3617 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-gnjilane highcharts-key-kv-842"></path>
                            <path 
                                id="municipality3"
                                d="M 1949 761 L 1949 1006 L 1935 1141 L 1875 1345 L 1662 1814 L 1509 2013 L 1743 2254 L 1864 2347 L 2120 2467 L 2248 2405 L 2350 2385 L 2438 2541 L 2526 2678 L 2598 2619 L 2656 2540 L 2758 2520 L 2832 2697 L 2962 2677 L 3035 2520 L 3107 2421 L 3136 2304 L 3310 2185 L 3514 2185 L 3660 2165 L 3682 1969 L 3644 1874 L 3630 1748 L 3687 1631 L 3858 1569 L 4017 1552 L 4117 1524 L 4230 1457 L 4335 1330 L 4359 1220 L 3421 902 L 3275 819 L 3249 1029 L 3177 1166 L 3060 1147 L 2899 1167 L 2724 1226 L 2505 1208 L 2301 1150 L 2125 974 L 1949 761 L 1949 761 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-prizren highcharts-key-kv-843"></path>
                            <path 
                                id="municipality4"
                                d="M 5913 3617 L 6060 3265 L 6128 2903 L 5811 2867 L 5811 2713 L 5887 2617 L 5727 2578 L 5611 2499 L 5568 2322 L 5466 2322 L 5349 2263 L 5277 2302 L 5131 2302 L 5000 2341 L 4941 2439 L 4840 2498 L 4781 2615 L 4679 2615 L 4679 2459 L 4694 2341 L 4621 2341 L 4519 2419 L 4382 2462 L 4305 2422 L 4280 2529 L 4288 2646 L 4272 2770 L 4199 2910 L 4336 2953 L 4766 3008 L 4810 3125 L 4824 3282 L 4912 3361 L 5013 3478 L 5042 3635 L 5173 3635 L 5391 3597 L 5652 3617 L 5913 3617 L 5913 3617 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-uroševac highcharts-key-kv-7324"></path>
                            <path
                                id="municipality5" 
                                d="M 3709 3992 L 3837 4047 L 3939 4165 L 4070 4165 L 4186 4243 L 4215 4380 L 4360 4517 L 4490 4498 L 4621 4557 L 4809 4518 L 4969 4576 L 5056 4537 L 5143 4439 L 5302 4518 L 5404 4459 L 5592 4421 L 5737 4480 L 5736 4813 L 5823 4853 L 6011 4814 L 6171 4736 L 6345 4640 L 6231 4564 L 6159 4450 L 6045 4306 L 6025 4186 L 6093 4046 L 6145 3906 L 6110 3783 L 5949 3765 L 5913 3617 L 5652 3617 L 5391 3597 L 5173 3635 L 5042 3635 L 4897 3733 L 4810 3655 L 4621 3674 L 4418 3733 L 4098 3772 L 3895 3655 L 3807 3780 L 3663 3812 L 3709 3992 L 3709 3992 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-lipljan highcharts-key-kv-7311"></path>
                            <path
                                id="municipality6"
                                d="M 5126 6028 L 5083 6165 L 5049 6268 L 5044 6458 L 4975 6624 L 4858 6761 L 4810 6877 L 4783 7077 L 4813 7247 L 4885 7368 L 4828 7659 L 4653 8026 L 4550 8182 L 4476 8253 L 4429 8350 L 4430 8494 L 4463 8634 L 4529 8596 L 4645 8552 L 5019 8531 L 5108 8462 L 5196 8284 L 5282 7883 L 5330 7782 L 5428 7725 L 5584 7823 L 5678 7808 L 5765 7637 L 5774 7400 L 5807 7158 L 5965 6972 L 6028 6955 L 6159 6956 L 6283 6905 L 6393 6813 L 6451 6777 L 6631 6736 L 6745 6731 L 6798 6673 L 6799 6472 L 6776 6344 L 6701 6087 L 6682 5969 L 6513 5973 L 6412 6090 L 6296 6188 L 6209 6344 L 5992 6245 L 5819 6186 L 5762 6068 L 5661 6048 L 5589 5970 L 5487 6028 L 5429 6146 L 5357 6224 L 5299 6106 L 5227 6028 L 5126 6028 L 5126 6028 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-podujevo highcharts-key-kv-7309"></path>
                            <path 
                                id="municipality7"
                                d="M 1691 4212 L 1903 4037 L 1765 3949 L 1705 3785 L 1727 3576 L 1718 3091 L 1850 2899 L 2121 2708 L 2219 2620 L 2120 2467 L 1864 2347 L 1743 2254 L 1509 2013 L 1415 2116 L 717 2636 L 629 2651 L 242 2645 L 174 2656 L 128 2709 L 80 2834 L 84 2992 L 38 3098 L -50 3175 L -75 3223 L -68 3272 L -9 3361 L -6 3424 L -34 3483 L -140 3601 L -295 3987 L -81 4010 L 4 3774 L 308 3634 L 569 3553 L 686 3729 L 802 3688 L 948 3746 L 1007 3883 L 1052 4098 L 1226 4176 L 1329 4332 L 1518 4331 L 1691 4212 L 1691 4212 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-ðakovica highcharts-key-kv-7321"></path>
                            <path 
                                id="municipality8"
                                d="M 1691 4212 L 1663 4389 L 1606 4526 L 1491 4645 L 1477 4782 L 1565 4899 L 1609 5016 L 1493 5096 L 1437 5312 L 1611 5369 L 1799 5309 L 1987 5348 L 2074 5465 L 2234 5523 L 2377 5527 L 2508 5354 L 2619 5285 L 2717 5263 L 2786 5108 L 2821 4934 L 3009 4854 L 3140 4852 L 2896 4481 L 2707 4482 L 2334 4199 L 2055 4104 L 1903 4037 L 1691 4212 L 1691 4212 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-klina highcharts-key-kv-7319"></path>
                            <path 
                                id="municipality9"
                                d="M -295 3987 L -391 4112 L -672 4271 L -787 4401 L -718 4500 L -710 4600 L -725 4707 L -722 4831 L -701 4893 L -636 5015 L -558 5211 L -360 5072 L -56 5049 L -101 4912 L -15 4813 L 115 4753 L 288 4712 L 418 4652 L 549 4730 L 679 4690 L 736 4571 L 807 4394 L 908 4296 L 1097 4373 L 1213 4391 L 1329 4332 L 1226 4176 L 1052 4098 L 1007 3883 L 948 3746 L 802 3688 L 686 3729 L 569 3553 L 308 3634 L 4 3774 L -81 4010 L -295 3987 L -295 3987 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-decani highcharts-key-kv-7320"></path>
                            <path 
                                id="municipality9"
                                d="M 3566 6362 L 3740 6499 L 3884 6538 L 4014 6537 L 4014 6655 L 4029 6890 L 4130 6969 L 4260 6949 L 4317 7126 L 4260 7282 L 4346 7322 L 4519 7243 L 4663 7282 L 4813 7247 L 4783 7077 L 4810 6877 L 4858 6761 L 4975 6624 L 5044 6458 L 5049 6268 L 4914 6192 L 4817 5974 L 4756 5775 L 4743 5663 L 4597 5522 L 4335 5424 L 4206 5497 L 4082 5626 L 3941 5812 L 3840 5851 L 3710 5832 L 3637 5852 L 3667 6185 L 3566 6362 L 3566 6362 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-vucitrn highcharts-key-kv-7304"></path>
                            <path fill="rgb(38,82,168)"
                                id="municipality10"
                                d="M 7129 4466 L 6984 4465 L 6882 4543 L 6766 4484 L 6796 4307 L 6666 4228 L 6564 4306 L 6447 4423 L 6317 4384 L 6159 4450 L 6231 4564 L 6345 4640 L 6459 4783 L 6567 4904 L 6894 4916 L 7142 4760 L 7215 4604 L 7129 4466 L 7129 4466 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-novo-brdo highcharts-key-kv-7313"></path>
                            <path 
                                id="municipality11"
                                d="M 7129 4466 L 7215 4604 L 7142 4760 L 6894 4916 L 6974 4993 L 7035 5103 L 6967 5230 L 6798 5352 L 6752 5492 L 7069 5503 L 7203 5798 L 7559 5751 L 7687 5708 L 7774 5633 L 7957 5406 L 8052 5344 L 8135 5345 L 8378 5458 L 8499 5462 L 8668 5425 L 8814 5330 L 8863 5162 L 8831 5094 L 8649 4982 L 8625 4804 L 8569 4749 L 8567 4694 L 8613 4599 L 8558 4422 L 8280 3953 L 8055 3724 L 8005 3638 L 7878 3639 L 7708 3696 L 7466 3762 L 7509 3939 L 7377 4114 L 7246 4251 L 7129 4466 L 7129 4466 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-kosovska-kamenica highcharts-key-kv-7314"></path>
                            <path 
                                id="municipality12"
                                d="M 3322 7049 L 3553 7028 L 3697 6989 L 3755 7146 L 3770 7361 L 3597 7460 L 3453 7617 L 3698 7734 L 3814 7969 L 3943 7910 L 4073 7930 L 4145 8028 L 4275 8126 L 4318 8243 L 4476 8253 L 4550 8182 L 4653 8026 L 4828 7659 L 4885 7368 L 4813 7247 L 4663 7282 L 4519 7243 L 4346 7322 L 4260 7282 L 4317 7126 L 4260 6949 L 4130 6969 L 4029 6890 L 4014 6655 L 4014 6537 L 3884 6538 L 3740 6499 L 3566 6362 L 3379 6382 L 3263 6382 L 3061 6539 L 2946 6716 L 3062 6794 L 3178 6814 L 3322 7049 L 3322 7049 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-kosovska-mitrovica highcharts-key-kv-7303"></path>
                            <path fill="rgb(230,235,245)"
                                id="municipality13"
                                d="M 3682 1969 L 3660 2165 L 3514 2185 L 3310 2185 L 3136 2304 L 3107 2421 L 3035 2520 L 2962 2677 L 2832 2697 L 2861 2952 L 2891 3108 L 3124 3127 L 3124 3284 L 3241 3382 L 3357 3401 L 3459 3362 L 3604 3440 L 3764 3420 L 3735 3596 L 3663 3734 L 3663 3812 L 3807 3780 L 3895 3655 L 3973 3545 L 4139 3460 L 4226 3314 L 4143 3174 L 4160 3067 L 4114 2983 L 4199 2910 L 4272 2770 L 4288 2646 L 4280 2529 L 4305 2422 L 4288 2221 L 4095 2165 L 3959 2019 L 3846 1975 L 3748 1956 L 3682 1969 L 3682 1969 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-suva-reka highcharts-key-kv-7316"></path>
                            <path 
                                id="municipality14"
                                d="M 4199 2910 L 4114 2983 L 4160 3067 L 4143 3174 L 4226 3314 L 4139 3460 L 3973 3545 L 3895 3655 L 4098 3772 L 4418 3733 L 4621 3674 L 4810 3655 L 4897 3733 L 5042 3635 L 5013 3478 L 4912 3361 L 4824 3282 L 4810 3125 L 4766 3008 L 4336 2953 L 4199 2910 L 4199 2910 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-štimlje highcharts-key-kv-7323"></path>
                            <path 
                                id="municipality15"
                                d="M 6060 3265 L 6233 3370 L 6437 3462 L 6496 3325 L 6597 3267 L 6698 3159 L 6817 2974 L 7006 2936 L 7124 2642 L 7197 2525 L 7271 2329 L 7236 2093 L 7157 1996 L 7098 2010 L 7045 2064 L 6979 2085 L 6886 2032 L 6895 1965 L 6937 1898 L 6943 1847 L 6882 1840 L 6684 1915 L 6585 1907 L 6550 1878 L 6392 2020 L 6240 2033 L 6042 2103 L 5896 2299 L 5911 2425 L 6009 2594 L 5988 2664 L 5887 2617 L 5811 2713 L 5811 2867 L 6128 2903 L 6060 3265 L 6060 3265 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-vitina highcharts-key-kv-7312"></path>
                            <path fill="rgb(38,82,168)"
                                id="municipality16"
                                d="M 3682 1969 L 3748 1956 L 3846 1975 L 3959 2019 L 4095 2165 L 4288 2221 L 4305 2422 L 4382 2462 L 4519 2419 L 4621 2341 L 4694 2341 L 4679 2459 L 4679 2615 L 4781 2615 L 4840 2498 L 4941 2439 L 5000 2341 L 5131 2302 L 5204 2145 L 5204 2086 L 5117 2008 L 5029 1969 L 5029 1851 L 5060 1689 L 4923 1601 L 4666 1348 L 4522 1276 L 4359 1220 L 4335 1330 L 4230 1457 L 4117 1524 L 4017 1552 L 3858 1569 L 3687 1631 L 3630 1748 L 3644 1874 L 3682 1969 L 3682 1969 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className=" municipality highcharts-point highcharts-name-štrpce highcharts-key-kv-7325"></path>
                            <path 
                                id="municipality17"
                                d="M 5131 2302 L 5277 2302 L 5349 2263 L 5466 2322 L 5568 2322 L 5611 2499 L 5727 2578 L 5887 2617 L 5988 2664 L 6009 2594 L 5911 2425 L 5896 2299 L 6042 2103 L 6240 2033 L 6392 2020 L 6550 1878 L 6511 1846 L 6178 1332 L 6172 1265 L 6201 1183 L 6216 963 L 6205 892 L 6145 879 L 5854 937 L 5784 1009 L 5731 1118 L 5638 1271 L 5435 1469 L 5219 1636 L 5142 1677 L 5060 1689 L 5029 1851 L 5029 1969 L 5117 2008 L 5204 2086 L 5204 2145 L 5131 2302 L 5131 2302 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-kacanik highcharts-key-kv-7326"></path>
                            <path fill="rgb(38,82,168)"
                                id="municipality18"
                                d="M 2352 8012 L 2502 8241 L 2721 8188 L 2836 8050 L 3066 8049 L 3238 7951 L 3368 7852 L 3410 7715 L 3453 7617 L 3597 7460 L 3770 7361 L 3755 7146 L 3697 6989 L 3553 7028 L 3322 7049 L 3207 7166 L 3050 7422 L 2935 7560 L 2978 7677 L 2864 7795 L 2634 7953 L 2352 8012 L 2352 8012 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-zvecan highcharts-key-kv-844"></path>
                            <path 
                                id="municipality19"
                                d="M 4360 4517 L 4302 4616 L 4186 4714 L 4273 4812 L 4331 4968 L 4403 5008 L 4403 5125 L 4490 5165 L 4621 5047 L 4794 5047 L 4910 5067 L 5069 5184 L 5098 4969 L 5142 4871 L 5070 4733 L 4969 4576 L 4809 4518 L 4621 4557 L 4490 4498 L 4360 4517 L 4360 4517 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className=" municipality highcharts-point highcharts-name-kosovo-polje highcharts-key-kv-845"></path>
                            <path 
                                id="municipality20"
                                d="M -558 5211 L -612 5318 L -936 5635 L -999 5754 L -987 5905 L -941 5968 L -768 6104 L -704 6133 L -503 6078 L -294 5949 L -103 5891 L 39 6048 L 49 6122 L 46 6265 L 94 6349 L 142 6380 L 359 6458 L 667 6508 L 736 6336 L 731 5729 L 802 5610 L 946 5472 L 1133 5373 L 1321 5371 L 1437 5312 L 1493 5096 L 1609 5016 L 1565 4899 L 1477 4782 L 1491 4645 L 1606 4526 L 1663 4389 L 1691 4212 L 1518 4331 L 1329 4332 L 1213 4391 L 1097 4373 L 908 4296 L 807 4394 L 736 4571 L 679 4690 L 549 4730 L 418 4652 L 288 4712 L 115 4753 L -15 4813 L -101 4912 L -56 5049 L -360 5072 L -558 5211 L -558 5211 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-pec highcharts-key-kv-841"></path>
                            <path
                                id="municipality21"
                                d="M 2120 2467 L 2219 2620 L 2121 2708 L 1850 2899 L 1718 3091 L 1727 3576 L 1705 3785 L 1765 3949 L 1903 4037 L 2081 3857 L 2197 3817 L 2371 3836 L 2502 3835 L 2588 3698 L 2558 3521 L 2645 3286 L 2891 3108 L 2861 2952 L 2832 2697 L 2758 2520 L 2656 2540 L 2598 2619 L 2526 2678 L 2438 2541 L 2350 2385 L 2248 2405 L 2120 2467 L 2120 2467 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-orahovac highcharts-key-kv-7322" data-z-index="0"></path>
                            <path 
                                id="municipality22"
                                d="M 3709 3992 L 3663 3812 L 3663 3734 L 3735 3596 L 3764 3420 L 3604 3440 L 3459 3362 L 3357 3401 L 3241 3382 L 3124 3284 L 3124 3127 L 2891 3108 L 2645 3286 L 2558 3521 L 2588 3698 L 2502 3835 L 2371 3836 L 2197 3817 L 2081 3857 L 1903 4037 L 2055 4104 L 2334 4199 L 2707 4482 L 2896 4481 L 3140 4852 L 3230 4852 L 3289 4765 L 3297 4657 L 3326 4512 L 3320 4270 L 3287 4188 L 3522 4081 L 3622 4241 L 3664 4150 L 3709 3992 L 3709 3992 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-mališevo highcharts-key-kv-7317" data-z-index="0"></path>
                            <path 
                                id="municipality23"
                                d="M 1949 761 L 2125 974 L 2301 1150 L 2505 1208 L 2724 1226 L 2899 1167 L 3060 1147 L 3177 1166 L 3249 1029 L 3275 819 L 3163 684 L 3106 520 L 3034 143 L 3027 -31 L 3098 -336 L 3075 -520 L 3014 -663 L 2919 -827 L 2802 -957 L 2679 -999 L 2624 -958 L 2516 -805 L 2462 -772 L 2426 -796 L 2363 -912 L 2320 -948 L 2227 -952 L 2158 -915 L 2026 -773 L 2030 -717 L 2002 -623 L 2001 -567 L 2028 -475 L 2152 -342 L 2213 -203 L 2213 -106 L 2185 -5 L 2159 147 L 1983 618 L 1949 761 L 1949 761 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-dragaš highcharts-key-kv-7315" data-z-index="0"></path>
                            <path fill="rgb(38,82,168)"
                                id="municipality24"
                                d="M 3322 7049 L 3178 6814 L 3062 6794 L 2946 6716 L 3061 6539 L 3046 6265 L 2959 6285 L 2859 6442 L 2744 6639 L 2614 6659 L 2528 6639 L 2421 6489 L 2327 6690 L 2205 6846 L 2049 6945 L 1657 6986 L 1626 7075 L 1502 7155 L 1414 7251 L 1463 7465 L 1559 7590 L 1655 7618 L 1754 7618 L 1863 7655 L 1908 7697 L 1993 7864 L 2044 7917 L 2167 7896 L 2235 7897 L 2352 8012 L 2634 7953 L 2864 7795 L 2978 7677 L 2935 7560 L 3050 7422 L 3207 7166 L 3322 7049 L 3322 7049 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-zubin-potok highcharts-key-kv-7306" data-z-index="0"></path>
                            <path 
                                id="municipality25"
                                d="M 1437 5312 L 1321 5371 L 1133 5373 L 946 5472 L 802 5610 L 731 5729 L 736 6336 L 667 6508 L 815 6532 L 1281 6630 L 1553 6742 L 1681 6912 L 1657 6986 L 2049 6945 L 2205 6846 L 2327 6690 L 2421 6489 L 2501 6222 L 2475 6030 L 2368 5716 L 2377 5527 L 2234 5523 L 2074 5465 L 1987 5348 L 1799 5309 L 1611 5369 L 1437 5312 L 1437 5312 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-istok highcharts-key-kv-7318" data-z-index="0"></path>
                            <path 
                                id="municipality26"
                                d="M 3061 6539 L 3263 6382 L 3379 6382 L 3566 6362 L 3667 6185 L 3637 5852 L 3710 5832 L 3840 5851 L 3941 5812 L 4082 5626 L 3995 5566 L 3878 5541 L 3804 5497 L 3723 5391 L 3497 5523 L 3428 5431 L 3422 5359 L 3379 5270 L 3281 5182 L 3164 4951 L 3140 4852 L 3009 4854 L 2821 4934 L 2786 5108 L 2717 5263 L 2619 5285 L 2508 5354 L 2377 5527 L 2368 5716 L 2475 6030 L 2501 6222 L 2421 6489 L 2528 6639 L 2614 6659 L 2744 6639 L 2859 6442 L 2959 6285 L 3046 6265 L 3061 6539 L 3061 6539 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-srbica highcharts-key-kv-7305" data-z-index="0"></path>
                            <path fill="rgb(230,235,245)"
                                id="municipality27"
                                d="M 4331 4968 L 4273 4812 L 4186 4714 L 4302 4616 L 4360 4517 L 4215 4380 L 4186 4243 L 4070 4165 L 3939 4165 L 3837 4047 L 3709 3992 L 3664 4150 L 3622 4241 L 3522 4081 L 3287 4188 L 3320 4270 L 3326 4512 L 3297 4657 L 3289 4765 L 3230 4852 L 3140 4852 L 3164 4951 L 3281 5182 L 3379 5270 L 3422 5359 L 3428 5431 L 3497 5523 L 3723 5391 L 3804 5497 L 3878 5541 L 3995 5566 L 4082 5626 L 4206 5497 L 4335 5424 L 4172 5341 L 4172 5223 L 4215 5067 L 4331 4968 L 4331 4968 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-glogovac highcharts-key-kv-7307" data-z-index="0"></path>
                            <path 
                                id="municipality28"
                                d="M 5069 5184 L 4910 5067 L 4794 5047 L 4621 5047 L 4490 5165 L 4403 5125 L 4403 5008 L 4331 4968 L 4215 5067 L 4172 5223 L 4172 5341 L 4335 5424 L 4597 5522 L 4743 5663 L 4756 5775 L 4817 5974 L 4914 6192 L 5049 6268 L 5083 6165 L 5126 6028 L 4996 5871 L 4982 5694 L 5054 5518 L 5098 5381 L 5069 5184 L 5069 5184 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-obilic highcharts-key-kv-7308" data-z-index="0"></path>
                            <path
                                id="municipality29" 
                                d="M 3453 7617 L 3410 7715 L 3368 7852 L 3238 7951 L 3066 8049 L 2836 8050 L 2721 8188 L 2502 8241 L 2622 8495 L 2646 8681 L 2605 8728 L 2488 8724 L 2439 8739 L 2375 8862 L 2328 9028 L 2262 9172 L 2248 9258 L 2285 9357 L 2331 9390 L 2513 9397 L 2637 9445 L 3076 9774 L 3212 9834 L 3351 9851 L 3491 9807 L 3597 9719 L 3690 9608 L 3743 9500 L 3669 9520 L 3605 9461 L 3599 9310 L 3583 9211 L 3560 9205 L 3596 9142 L 4463 8634 L 4430 8494 L 4429 8350 L 4476 8253 L 4318 8243 L 4275 8126 L 4145 8028 L 4073 7930 L 3943 7910 L 3814 7969 L 3698 7734 L 3453 7617 L 3453 7617 Z"
                                stroke="#cccccc" stroke-width="inherit" opacity="1"
                                className="municipality highcharts-point highcharts-name-leposavic highcharts-key-kv-7302" data-z-index="0"></path>
                        </g>
                    </g>
                    <g className="highcharts-markers highcharts-series-1 highcharts-map-series highcharts-color-1 highcharts-tracker"
                        data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)" clip-path="none"
                        style={{cursor: 'pointer'}}></g>
                </g>
              
                <g id="map" className="highcharts-data-labels highcharts-series-1 highcharts-map-series highcharts-color-1 highcharts-tracker"
                    data-z-index="6" opacity="1" transform="translate(10,10) scale(1 1)" clip-path="url(#highcharts-0wbmczz-60-)"
                    style={{cursor: 'pointer'}}>
                    <g onClick={() => handleClick('Prishtinë')} id="municipality-Prishtinë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(643,231)"><text x="0" data-z-index="1" y="11"
                        style={{
                            color: '#FFFFFF',
                            fontSize: '11px',
                            fontWeight: 'bold',
                            fill: '#FFFFFF', // This property is usually used for SVG elements
                          }} >
                            <tspan className="" 
                                stroke-linejoin="round"  id="prishtina">Prishtinë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Gjilan" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(714,334)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Gjilan<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Prizren" onClick={() => handleClick('Prizren')} className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(464,429)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Prizren<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Ferizaj" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(618,371)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Ferizaj<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Lipjan" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(603,308)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Lipjan<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Podujevë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(620,152)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Podujevë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Obiliq" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(586,240)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Obiliq<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Gllogoc" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(528,270)"><text x="0" data-z-index="1" y="11"
                        style={{
                            color: '#fff',
                            fontSize: '11px',
                            fontWeight: 'bold',
                            fill: '#fff', // This will work if it's an SVG element
                          }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Gllogoc<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Malishevë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(475,322)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Malishevë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Gjakovë" onClick={() => handleClick('Gjakovë')} className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(378,354)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Gjakovë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Rahovec" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(446,361)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Rahovec<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Klinë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(451,272)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Klinë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Deçan" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(344,303)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Deçan<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Istog" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(423,202)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Istog<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Skënderaj" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(486,217)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Skënderaj<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Vushtrri" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(558,185)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Vushtrri<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Novobërdë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(676,280)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Novobërdë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Kamenicë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(741,266)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Kamenicë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Mitrovicë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(548,119)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Mitrovicë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Suharekë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(520,379)"><text x="0" data-z-index="1" y="11"
                        style={{
                            color: '#FFFFFF',
                            fontSize: '11px',
                            fontWeight: 'bold',
                            fill: '#fff', // This property is usually used for SVG elements
                          }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Suharekë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Shtime" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(574,350)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Shtime<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Viti" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(697,394)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Viti<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Shtërpcë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(558,431)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Shtërpcë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Kaçanik" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(639,438)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Kaçanik<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Zveçan" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(500,116)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Zveçan<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Leposaviq" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(505,59)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Leposaviq<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Zubin Potok" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(447,132)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Zubin Potok<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Fushë Kosovë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(566,268)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round" >Fushë Kosovë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Dragash" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(465,528)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className="" 
                                stroke-linejoin="round"  href="gjakova.html">Dragash<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                    <g id="municipality-Pejë" className="highcharts-label highcharts-data-label highcharts-data-label-color-undefined" data-z-index="1"
                        style={{cursor: 'pointer'}} transform="translate(354,248)"><text x="0" data-z-index="1" y="11"
                            style={{
        color: '#FFFFFF',
        fontSize: '11px',
        fontWeight: 'bold',
        fill: '#FFFFFF', // This property is usually used for SVG elements
      }} >
                            <tspan className=""  
                              >Pejë<tspan x="0" y="11">​</tspan>
                            </tspan>
                        </text></g>
                </g>
                
                 
         
            </svg>
            </div>
            <div className="col-lg-7 row mt-4">
          <div className="col-12">
            {loading ? (
              <div className="text-center py-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : mapResults.length > 0 ? (
              <div className="bg-white rounded shadow p-4">
                <h3 className="mb-4">
                  Found {mapResults.length} {mapResults.length === 1 ? 'business' : 'businesses'} in {selectedMunicipality}
                </h3>
                {/* <TableComponent businesses={mapResults} companies={[]} simplified={true} /> */}
                <TableComponent 
        companies={mapResults}
        simplified={true}
      />
              </div>
            ) : selectedMunicipality && (
              <div className="text-center py-4 text-muted">
                No businesses found in {selectedMunicipality}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
</div>
  );
};

export default KosovoMap;
