import React, { useState, useEffect } from 'react';
import { Network } from 'vis-network/standalone';
import { useSearchParams } from 'react-router-dom';
import { getBusinessDetails } from '../api';
import Header from '../components/Header';
import Footer from '../components/Footer';

const BusinessDetails = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [businessDetails, setBusinessDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        setLoading(true);
        const response = await getBusinessDetails(id);
        const data = {
          name: response.business.business_name,
          type: response.business.business_type,
          uniqueId: response.business.unique_identifier,
          registrationDate: response.business.registration_date,
          employeeCount: response.business.number_of_employees,
          address: response.business.address,
          phone: response.business.phone,
          capital: response.business.capital,
          status: response.business.status_in_arbk,
          authorizedRepresentative: response.representatives[0] ? {
            firstName: response.representatives[0].representative_name,
            lastName: response.representatives[0].representative_surname,
            position: response.representatives[0].position,
            authorizations: response.representatives[0].authorizations
          } : null,
          owners: response.owners.map(owner => ({
            name: owner.name,
            capitalInEuros: owner.capital_in_euros || 0,
            capitalPercentage: owner.capital_percentage || 0
          })),
          activities: response.activities.map(activity => ({
            code: activity.code,
            description: activity.description,
            type: activity.activity_type
          }))
        };
        setBusinessDetails(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching business details:', err);
        setError('Failed to load business details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBusinessDetails();
    }
  }, [id]);

  useEffect(() => {
    const container = document.getElementById('graph');
    const data = {
      nodes: [
        { id: 1, shape: 'image', image: 'https://cdn-icons-png.flaticon.com/512/1324/1324872.png', label: businessDetails?.name || '' },
        ...(businessDetails?.owners || []).map((owner, index) => ({
          id: index + 2,
          shape: 'image',
          image: 'https://cdn-icons-png.flaticon.com/512/3024/3024605.png',
          label: owner.name
        }))
      ],
      edges: (businessDetails?.owners || []).map((_, index) => ({
        from: 1,
        to: index + 2
      }))
    };
    const options = {
      nodes: {
        borderWidth: 0,
        size: 42,
        color: {
          border: '#222',
          background: 'transparent'
        },
        font: {
          color: '#111',
          size: 15,
          strokeWidth: 0.5,
          strokeColor: '#111'
        }
      },
      edges: {
        color: {
          color: '#CCC',
          highlight: '#438a5e'
        },
        width: 3,
        length: 275,
        hoverWidth: .05
      }
    };
    
    if (container && businessDetails) {
      new Network(container, data, options);
    }
  }, [businessDetails]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!businessDetails) {
    return null;
  }

  return (
    <>
      <Header />
      <div className="details">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card first">
                <div className="card-body">
                  <div>
                    <i className="fa fa-building fa-4x" style={{ color: 'green', opacity: 0.3 }}></i>              
                    <p style={{ fontSize: '20px' }}>{businessDetails.name}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-briefcase"></i>&nbsp;Emri tregtar</p>
                  </div>
                  <div>
                    <p>{businessDetails.name}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-flag"></i>&nbsp;Lloji i biznesit</p>
                  </div>
                  <div>
                    <p>{businessDetails.type}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-info"></i>&nbsp;Numri unik identifikues</p>
                  </div>
                  <div>
                    <p>{businessDetails.uniqueId}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-calendar"></i>&nbsp;Data e regjistrimit</p>
                  </div>
                  <div>
                    <p>{businessDetails.registrationDate}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-person"></i>&nbsp;Numri i puntoreve</p>
                  </div>
                  <div>
                    <p>{businessDetails.employeeCount}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-location-pin"></i>&nbsp;Adresa</p>
                  </div>
                  <div>
                    <p>{businessDetails.address}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-phone"></i>&nbsp;Telefoni</p>
                  </div>
                  <div>
                    <p>{businessDetails.phone}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-coins"></i>&nbsp;Kapitali</p>
                  </div>
                  <div>
                    <p>{businessDetails.capital}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="emri">
                    <p><i className="fa-solid fa-pen"></i>&nbsp;Statusi ne ARBK</p>
                  </div>
                  <div>
                    <p>{businessDetails.status}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
        <div className="container">
          <div className="row">
            <div className="col-md-12 tb">
              <p><i className="fa-solid fa-pen"></i>&nbsp;Perfaques i autorizuar</p>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Emri</th>
                    <th scope="col">Mbiemri</th>
                    <th scope="col">Pozita ne biznes</th>
                    <th scope="col">Autorizimet</th>
                  </tr>
                </thead>
                <tbody>
                  {businessDetails.authorizedRepresentative && (
                    <tr>
                      <td>{businessDetails.authorizedRepresentative.firstName}</td>
                      <td>{businessDetails.authorizedRepresentative.lastName}</td>
                      <td>{businessDetails.authorizedRepresentative.position}</td>
                      <td>{businessDetails.authorizedRepresentative.authorizations}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 tb">
              <p><i className="fa-solid fa-percent"></i>&nbsp;Pronar/Aksionare</p>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Emri Mbiemri</th>
                    <th scope="col">Kapitali ne euro</th>
                    <th scope="col">Kapitali ne %</th>
                  </tr>
                </thead>
                <tbody>
                  {businessDetails.owners.map((owner, index) => (
                    <tr key={index}>
                      <td>{owner.name}</td>
                      <td>{owner.capitalInEuros}</td>
                      <td>{owner.capitalPercentage}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 tb">
              <p><i className="fa-solid fa-chart-line"></i>&nbsp;Aktiviteti</p>
              <table className="table" style={{ marginTop: 0 }}>
                <thead>
                  <tr>
                    <th scope="col">Kodi</th>
                    <th scope="col">Pershkrimi</th>
                    <th scope="col">Tipi</th>
                  </tr>
                </thead>
                <tbody>
                  {businessDetails.activities.map((activity, index) => (
                    <tr key={index}>
                      <td>{activity.code}</td>
                      <td>{activity.description}</td>
                      <td>{activity.type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div id="graph"></div>
      </div>
      <Footer />
    </>
  );
};

export default BusinessDetails;