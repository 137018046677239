import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LogOut, Settings, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface AuthIndicatorProps {
  user: {
    name: string;
    email: string;
    avatar?: string;
  };
  onLogout: () => void;
}

const AuthIndicator: React.FC<AuthIndicatorProps> = ({ user, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleMyAccount = () => {
    setIsOpen(false);
    navigate('/my-account');
  };

  return (
    <div className="tw-relative">
      {/* User Button */}
      <button
        onClick={toggleDropdown}
        className="tw-flex tw-items-center tw-gap-2 tw-h-10 tw-px-4 tw-text-sm tw-font-medium tw-text-white tw-rounded-full tw-border-0 tw-outline-none tw-bg-gradient-to-r tw-from-[rgb(19,203,63)] tw-to-[rgb(67,138,94)] hover:tw-opacity-90 tw-transition-all"
      >
        {user.avatar ? (
          <img
            src={user.avatar}
            alt={user.name}
            className="tw-w-6 tw-h-6 tw-rounded-full tw-object-cover"
          />
        ) : (
          <div className="tw-w-6 tw-h-6 tw-rounded-full tw-bg-white/20 tw-flex tw-items-center tw-justify-center">
            <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
              <User 
                className="tw-text-white"
                style={{
                  width: '16px',
                  height: '16px',
                  display: 'block',
                  margin: 'auto'
                }}
              />
            </div>
          </div>
        )}
        <span>{user.name}</span>
      </button>

      {/* Dropdown Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black/5 tw-divide-y tw-divide-gray-100"
          >
            {/* User Info Section */}
            <div className="tw-px-4 tw-py-3">
              <p className="tw-text-sm tw-font-medium tw-text-gray-900">{user.name}</p>
              <p className="tw-text-sm tw-text-gray-500">{user.email}</p>
            </div>

            {/* Menu Items */}
            <motion.div
              className="tw-py-1"
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
            >
              <button
                onClick={handleMyAccount}
                className="tw-flex tw-w-full tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-50"
              >
                <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                  <User 
                    className="tw-text-gray-400"
                    style={{
                      width: '16px',
                      height: '16px',
                      display: 'block',
                      margin: 'auto'
                    }}
                  />
                </div>
                <span>My Account</span>
              </button>
              <button
                onClick={onLogout}
                className="tw-flex tw-w-full tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-text-sm tw-text-red-600 hover:tw-bg-gray-50"
              >
                <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                  <LogOut 
                    className="tw-text-red-400"
                    style={{
                      width: '16px',
                      height: '16px',
                      display: 'block',
                      margin: 'auto'
                    }}
                  />
                </div>
                <span>Sign out</span>
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AuthIndicator;