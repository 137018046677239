import React from 'react';
import logo from './logo.svg';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import { ModalProvider } from './context/ModalContext';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import BusinessDetails from './pages/BusinessDetails';
import MyAccount from './pages/MyAccount.tsx';

function App() {
  return (
    <ModalProvider>
      <AuthProvider>
        <Router>
          <Routes>
            {/* <Route exact element={<PrivateRoute />}>
              <Route exact path="/Dashboard" element={<Dashboard />} />
              <Route path="/login" element={<Home />} />
            </Route> */}
            <Route path="/" element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/details' element={<BusinessDetails />} />
            <Route path='/my-account' element={<MyAccount />} />
            {/* <Route path='details' element={<BusinessDetails />} /> */}
          </Routes>
        </Router>
      </AuthProvider>
    </ModalProvider>
  );
}

export default App;
