// src/api/filterByCheckboxServices.js
import axios from 'axios';

// const API_BASE_URL = 'http://localhost:8000/api';
const API_BASE_URL = 'https://backend.kseco.ch/api';


export const filterByCheckboxServices = async (filterParams) => {
    try {
        // Convert the filter parameters to array format
        const params = {};
        Object.entries(filterParams).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
                params[`${key}[]`] = value;
            }
        });

        console.log('Sending params to backend:', params);

        const response = await axios.get(`${API_BASE_URL}/filter-by-checkbox-services/`, { 
            params,
            paramsSerializer: params => {
                const searchParams = new URLSearchParams();
                Object.entries(params).forEach(([key, values]) => {
                    if (Array.isArray(values)) {
                        values.forEach(value => searchParams.append(key, value));
                    } else {
                        searchParams.append(key, values);
                    }
                });
                return searchParams.toString();
            }
        });

        console.log('Response from backend:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error filtering by checkbox services:', error);
        throw error;
    }
};
