import React, { useState } from "react";
import { Mail } from "lucide-react";
import authAPI from "../api/auth";
import { AnimatePresence, motion } from "framer-motion";
import { X } from "lucide-react";

const ForgotPasswordModal = ({ isOpen, onClose, onLoginClick }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authAPI.requestPasswordReset(email);
      setSuccess(true);
      setError(null);
    } catch (error) {
      console.error("Password reset request error:", error);
      setError(error.detail || "Failed to send reset email. Please try again.");
      setSuccess(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="tw-bg-white tw-rounded-xl tw-p-8 tw-w-full tw-max-w-md tw-relative"
          >
            {/* Close Button */}
            <button
              onClick={onClose}
              className="tw-absolute tw-right-4 tw-top-4 tw-text-gray-500 hover:tw-text-gray-700"
            >
              <X size={20} />
            </button>

            <form onSubmit={handleSubmit} className="tw-space-y-6">
              <div className="tw-text-center">
                <h2 className="tw-text-2xl tw-font-bold tw-text-gray-900">
                  Forgot Password
                </h2>
                <p className="tw-mt-2 tw-text-sm tw-text-gray-600">
                  Enter your email address and we'll send you a link to reset your password.
                </p>
              </div>

              {/* Email Input */}
              <div className="tw-relative">
                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <Mail 
                      className="tw-text-gray-400"
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'block',
                        margin: 'auto'
                      }}
                    />
                  </div>
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="tw-block tw-w-full tw-pl-8 tw-pr-3 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {error && (
                <p className="tw-text-red-600 tw-text-sm tw-mt-2">{error}</p>
              )}
              {success && (
                <p className="tw-text-green-600 tw-text-sm tw-mt-2">
                  Password reset instructions have been sent to your email.
                </p>
              )}

              {/* Submit Button */}
              <motion.button
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                type="submit"
                className="tw-w-full tw-bg-green-600 tw-text-white tw-py-2.5 tw-rounded-lg tw-font-medium hover:tw-bg-green-700 tw-transition-colors tw-shadow-lg hover:tw-shadow-xl"
              >
                Send Reset Link
              </motion.button>

              {/* Back to Login Link */}
              <p className="tw-text-center tw-mt-6 tw-text-sm tw-text-gray-600">
                Remember your password?{" "}
                <button
                  type="button"
                  onClick={() => onLoginClick()}
                  className="tw-font-medium tw-text-green-600 hover:tw-text-green-700"
                >
                  Sign in
                </button>
              </p>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ForgotPasswordModal;
