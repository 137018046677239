import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheck,
  faBriefcase,
  faGem,
  faCube 
} from '@fortawesome/free-solid-svg-icons';

function Pricing() {
  const plans = [
    { 
      icon: faCheck, 
      color: "", 
      price: 10, 
      name: "Standard", 
      features: ["50GB Disk Space", "50 Email Accounts", "50GB Monthly Bandwidth", "10 subdomains", "15 Domains"] 
    },
    { 
      icon: faBriefcase, 
      color: "green", 
      price: 20, 
      name: "Business", 
      features: ["60GB Disk Space", "60 Email Accounts", "60GB Monthly Bandwidth", "15 subdomains", "20 Domains"] 
    },
    { 
      icon: faGem, 
      color: "blue", 
      price: 30, 
      name: "Premium", 
      features: ["70GB Disk Space", "70 Email Accounts", "70GB Monthly Bandwidth", "20 subdomains", "25 Domains"] 
    },
    { 
      icon: faCube, 
      color: "red", 
      price: 40, 
      name: "Extra", 
      features: ["80GB Disk Space", "80 Email Accounts", "80GB Monthly Bandwidth", "25 subdomains", "30 Domains"] 
    }
  ];

  return (
    <div className="demo bg-cover bg-gray default-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="site-heading text-center">
              <h4>Pricing Plans</h4>
              <h2>How we works</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pricing-row">
          {plans.map((plan, index) => (
            <div key={index} className="col-lg-3 col-md-6">
              <div className={`pricingTable ${plan.color}`}>
                <div className="pricingTable-header">
                  <div className="icon-wrapper">
                    <FontAwesomeIcon 
                      icon={plan.icon} 
                    className="pricing-icon fa-fw"
                    style={{
                      width: '32px',
                      height: '32px',
                      display: 'block',
                      margin: 'auto'
                    }}
                    />
                  </div>
                  <div className="price-wrapper">
                    <div className="price-value">
                      ${plan.price}.00
                      <span className="month">per month</span>
                    </div>
                  </div>
                </div>
                <h3 className="heading">{plan.name}</h3>
                <div className="pricing-content">
                  <ul>
                    {plan.features.map((feature, fIndex) => (
                      <li key={fIndex}>
                        <b>{feature.split(' ')[0]}</b> {feature.split(' ').slice(1).join(' ')}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="pricingTable-signup">
                  <a href="#">sign up</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Pricing;