import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const delta = 2;
    const range = [];
    const rangeWithDots = [];
    let l;

    range.push(1);

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < totalPages && i > 1) {
        range.push(i);
      }
    }

    range.push(totalPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-4 tw-mb-8">
      <div className="tw-flex tw-items-center tw-space-x-2">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="tw-px-3 tw-py-1 tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-text-gray-600 hover:tw-bg-gray-50 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
        >
          Previous
        </button>

        <div className="tw-flex tw-items-center tw-space-x-1">
          {getPageNumbers().map((pageNumber, index) => (
            <React.Fragment key={index}>
              {pageNumber === '...' ? (
                <span className="tw-px-2 tw-py-1 tw-text-gray-500">...</span>
              ) : (
                <button
                  onClick={() => onPageChange(pageNumber)}
                  className={`tw-min-w-[32px] tw-px-2 tw-py-1 tw-rounded-md tw-text-sm ${
                    currentPage === pageNumber
                      ? 'tw-bg-[#438a5e] tw-text-white tw-font-medium'
                      : 'tw-bg-white tw-border tw-border-gray-300 tw-text-gray-600 hover:tw-bg-gray-50'
                  }`}
                >
                  {pageNumber}
                </button>
              )}
            </React.Fragment>
          ))}
        </div>

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="tw-px-3 tw-py-1 tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-text-gray-600 hover:tw-bg-gray-50 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
        >
          Next
        </button>
      </div>

      <div className="tw-mt-2 tw-text-sm tw-text-gray-500">
        Page {currentPage} of {totalPages}
      </div>
    </div>
  );
};

export default Pagination;
