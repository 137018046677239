import React, { useState, useEffect } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Pagination from '../components/services/Pagination';

import SearchBar from '../components/services/searchBar';
import FilterSection from '../components/services/FilterSection';
import SortDropdown from '../components/services/SortDropdown';
import BusinessTable from '../components/services/BusinessTable';

import { getBusinesses, searchBusinesses, getFilterOptions } from '../api';

const Services = () => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState('-id');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOptions, setFilterOptions] = useState({
    municipalities_options: [],
    activities_options: [],
    status_options: [],
    business_type_options: [],
    unique_identifier_options: []
  });
  const [selectedFilters, setSelectedFilters] = useState({
    municipality: [],
    activity_type: [],
    status_in_arbk: [],
    business_type: [],
    unique_identifier: []
  });

  // Fetch filter options on mount
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const options = await getFilterOptions();
        setFilterOptions(options);
      } catch (err) {
        console.error('Error fetching filter options:', err);
      }
    };
    fetchFilterOptions();
  }, []);

  // Fetch businesses when filters, sort, or page changes
  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        setLoading(true);
        const data = await getBusinesses(currentPage, selectedFilters, sortBy, searchTerm);
        setBusinesses(data.results);
        setTotalPages(data.total_pages);
      } catch (err) {
        setError('Failed to fetch businesses');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchBusinesses();
  }, [currentPage, selectedFilters, sortBy, searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setCurrentPage(1);
  };

  const handleFilterChange = (category, value) => {
    setSelectedFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      const filterKey = categoryToFieldMap[category] || category;
      
      if (newFilters[filterKey].includes(value)) {
        newFilters[filterKey] = newFilters[filterKey].filter(item => item !== value);
      } else {
        newFilters[filterKey] = [...newFilters[filterKey], value];
      }
      return newFilters;
    });
    setCurrentPage(1);
  };

  // Map frontend categories to backend field names
  const categoryToFieldMap = {
    municipalities: 'municipality',
    activities: 'activity_type',
    statuses: 'status_in_arbk',
    businessTypes: 'business_type',
    uniqueIdentifier: 'unique_identifier',
    genders: 'gender'
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <>
      <Header />
      <div className="tw-bg-white">
        <main className="tw-mx-auto tw-max-w-7xl tw-px-4 sm:tw-px-6 lg:tw-px-8">
          <div className="tw-flex tw-items-baseline tw-justify-between tw-border-b tw-border-gray-200 tw-pb-6 tw-pt-24">
            <h1 className="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900">KSecoKS</h1>

            <div className="tw-flex tw-items-center tw-gap-6">
              <SearchBar onSearch={handleSearch} />
              <div className="tw-ml-2">
                <SortDropdown
                  sortBy={sortBy}
                  onSortChange={handleSortChange}
                />
              </div>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="tw-pb-24 tw-pt-6">
            <h2 id="products-heading" className="tw-sr-only">
              Products
            </h2>

            <div className="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-grid-cols-4">
              {/* Filters */}
              <div className="tw-hidden lg:tw-block">
                <FilterCategory
                  title="Status"
                  options={filterOptions.status_options || []}
                  selectedOptions={selectedFilters.status_in_arbk}
                  onChange={(value) => handleFilterChange('statuses', value)}
                />
                <FilterCategory
                  title="Komuna"
                  options={filterOptions.municipalities_options || []}
                  selectedOptions={selectedFilters.municipality}
                  onChange={(value) => handleFilterChange('municipalities', value)}
                />
                <FilterCategory
                  title="Aktivitetet"
                  options={filterOptions.activities_options || []}
                  selectedOptions={selectedFilters.activity_type}
                  onChange={(value) => handleFilterChange('activities', value)}
                />
                <FilterCategory
                  title="Lloji i Biznesit"
                  options={filterOptions.business_type_options || []}
                  selectedOptions={selectedFilters.business_type}
                  onChange={(value) => handleFilterChange('businessTypes', value)}
                />
                <FilterCategory
                  title="Identifikuesi Unik"
                  options={filterOptions.unique_identifier_options || []}
                  selectedOptions={selectedFilters.unique_identifier}
                  onChange={(value) => handleFilterChange('uniqueIdentifier', value)}
                />
              </div>

              {/* Business list */}
              <div className="lg:tw-col-span-3">
                {loading ? (
                  <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
                    <div className="tw-animate-spin tw-rounded-full tw-h-32 tw-w-32 tw-border-b-2 tw-border-gray-900"></div>
                  </div>
                ) : (
                  <>
                    <BusinessTable businesses={businesses} />
                    {businesses.length > 0 && (
                      <div className="tw-mt-6 tw-flex tw-justify-center">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
};

const FilterCategory = ({ title, options = [], selectedOptions = [], onChange }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="tw-border-b tw-border-gray-200 tw-py-6">
      <h3 className="-tw-my-3 tw-flow-root">
        <button
          type="button"
          className="tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-white tw-py-3 tw-text-sm tw-text-gray-400 hover:tw-text-gray-500"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span className="tw-font-medium tw-text-gray-900">{title}</span>
          <span className="tw-ml-6 tw-flex tw-items-center">
            {isExpanded ? (
              <svg className="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg className="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
              </svg>
            )}
          </span>
        </button>
      </h3>
      {isExpanded && (
        <div className="tw-pt-6">
          <div className="tw-space-y-4">
            {options.map((option) => (
              <div key={option} className="tw-flex tw-items-center">
                <input
                  id={`filter-${title}-${option}`}
                  name={`${title}[]`}
                  value={option}
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => onChange(option)}
                  className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-indigo-600 focus:tw-ring-indigo-500"
                />
                <label
                  htmlFor={`filter-${title}-${option}`}
                  className="tw-ml-3 tw-text-sm tw-text-gray-600"
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;