import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Filter } from 'lucide-react';
import { getLatestBusinesses } from '../api/latestBusinesses';
import { filterByCheckboxServices } from '../api/filterByCheckBoxServices';
import TableComponent from './TableComponent';

const FormComponent = ({ 
    municipalities = [], 
    activities = [], 
    statuses = [],
    businessNames = [],
    businessTypes = [],
    capitalRanges = [],
    uniqueIds = [],
    registrationDates = [],
    genders = [],
    onSubmit 
}) => {
    const [selectedFilters, setSelectedFilters] = useState({
        municipality: [],
        activity_type: [],
        status_in_arbk: [],
        business_type: [],
        capital_range: [],
        unique_identifier: [],
        registration_date: [],
        gender: []
    });

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch latest businesses on component mount
    useEffect(() => {
        fetchLatestBusinesses();
    }, []);

    const fetchLatestBusinesses = async () => {
        try {
            const data = await getLatestBusinesses();
            setTableData(data.businesses || data || []);
            setLoading(false);
        } catch (err) {
            setError('Failed to load latest businesses');
            setLoading(false);
        }
    };

    // Map frontend categories to backend field names
    const categoryToFieldMap = {
        municipalities: 'municipality',
        activities: 'activity_type',
        statuses: 'status_in_arbk',
        businessTypes: 'business_type',
        capitalRanges: 'capital_range',
        uniqueIds: 'unique_identifier',
        registrationDates: 'registration_date',
        genders: 'gender'
    };

    const handleFilterChange = (category, value) => {
        const backendField = categoryToFieldMap[category];
        if (backendField) {
            setSelectedFilters(prev => ({
                ...prev,
                [backendField]: prev[backendField].includes(value)
                    ? prev[backendField].filter(item => item !== value)
                    : [...prev[backendField], value]
            }));
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            // Only include non-empty filter arrays
            const nonEmptyFilters = Object.fromEntries(
                Object.entries(selectedFilters).filter(([_, values]) => values.length > 0)
            );
            console.log('Sending filters to backend:', nonEmptyFilters);
            const response = await filterByCheckboxServices(nonEmptyFilters);
            // Extract businesses array from the response
            setTableData(response.businesses || []);
        } catch (err) {
            setError('Failed to apply filters');
            console.error('Filter error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleClearFilters = async () => {
        setSelectedFilters({
            municipality: [],
            activity_type: [],
            status_in_arbk: [],
            business_type: [],
            capital_range: [],
            unique_identifier: [],
            registration_date: [],
            gender: []
        });
        fetchLatestBusinesses(); // Reset to showing last 10 businesses
    };

    // Check if any filters are selected
    const hasActiveFilters = Object.values(selectedFilters).some(arr => arr.length > 0);

    return (
        <div className="tw-min-h-screen tw-bg-gray-50">
            <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-py-8">
                {/* Main Container */}
                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-8">
                    {/* Filters Sidebar */}
                    <div className="lg:tw-w-1/4">
                        <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-6">
                            <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
                                <h3 className="tw-text-lg tw-font-semibold tw-text-gray-900">
                                    <Filter className="tw-inline-block tw-w-5 tw-h-5 tw-mr-2 tw-text-gray-500" />
                                    Filters
                                </h3>
                                {hasActiveFilters && (
                                    <button
                                        onClick={handleClearFilters}
                                        className="tw-text-sm tw-text-gray-500 hover:tw-text-gray-700"
                                    >
                                        Clear all
                                    </button>
                                )}
                            </div>

                            <div className="tw-space-y-6">
                                {/* Filter Categories */}
                                {[
                                    { title: 'Municipality', data: municipalities, key: 'municipalities' },
                                    { title: 'Activity', data: activities, key: 'activities' },
                                    { title: 'Status', data: statuses, key: 'statuses' },
                                    { title: 'Business Type', data: businessTypes, key: 'businessTypes' },
                                    { title: 'Capital Range', data: capitalRanges, key: 'capitalRanges' }
                                ].map((category) => (
                                    <FilterCategory
                                        key={category.key}
                                        title={category.title}
                                        options={category.data}
                                        selected={selectedFilters[categoryToFieldMap[category.key]] || []}
                                        onChange={(value) => handleFilterChange(category.key, value)}
                                    />
                                ))}
                            </div>

                            <button
                                onClick={handleSubmit}
                                className="tw-mt-6 tw-w-full tw-bg-green-600 tw-text-white tw-py-2 tw-px-4 tw-rounded-md hover:tw-bg-green-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-green-500 focus:tw-ring-offset-2"
                            >
                                Apply Filters
                            </button>
                        </div>
                    </div>

                    {/* Table Section */}
                    <div className="lg:tw-w-3/4">
                        <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-6">
                            {loading ? (
                                <div className="tw-flex tw-justify-center tw-items-center tw-h-40">
                                    <div className="tw-animate-spin tw-rounded-full tw-h-8 tw-w-8 tw-border-b-2 tw-border-gray-900"></div>
                                </div>
                            ) : error ? (
                                <div className="tw-text-red-500 tw-text-center tw-py-4">{error}</div>
                            ) : (
                                <>
                                    <div className="tw-mb-4 tw-flex tw-justify-between tw-items-center">
                                        <span className="tw-text-sm tw-text-gray-500">
                                            {hasActiveFilters 
                                                ? `Showing ${tableData.length} filtered result${tableData.length !== 1 ? 's' : ''}`
                                                : 'Showing last 10 businesses'}
                                        </span>
                                    </div>
                                    <TableComponent 
                                        companies={tableData}
                                        simplified={true}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FilterCategory = ({ title, options, selected, onChange }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className="tw-border-b tw-border-gray-200 tw-pb-4">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="tw-flex tw-items-center tw-justify-between tw-w-full tw-text-left tw-font-medium tw-text-gray-900 hover:tw-text-green-600 tw-mb-2"
            >
                <span>{title}</span>
                <ChevronDown
                    className={`tw-w-5 tw-h-5 tw-transition-transform ${
                        isOpen ? 'tw-transform tw-rotate-180' : ''
                    }`}
                />
            </button>
            
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className="tw-max-h-48 tw-overflow-y-auto tw-pr-2 tw-space-y-2 tw-scrollbar-thin tw-scrollbar-thumb-gray-300 tw-scrollbar-track-gray-100">
                            {options.map((option, index) => (
                                <label
                                    key={index}
                                    className="tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer tw-py-1"
                                >
                                    <input
                                        type="checkbox"
                                        checked={selected.includes(option)}
                                        onChange={() => onChange(option)}
                                        className="tw-form-checkbox tw-h-4 tw-w-4 tw-text-green-600 tw-rounded tw-border-gray-300 focus:tw-ring-green-500"
                                    />
                                    <span className="tw-text-sm tw-text-gray-600">{option}</span>
                                </label>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default FormComponent;