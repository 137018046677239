import React, { useState } from 'react';

const SortDropdown = ({ sortBy, onSortChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSort = (sortValue) => {
    onSortChange(sortValue);
    setIsOpen(false);
  };

  return (
    <div className="tw-relative tw-inline-block tw-text-left">
      <div>
        <button
          type="button"
          className="tw-group tw-inline-flex tw-justify-center tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-text-gray-900"
          onClick={() => setIsOpen(!isOpen)}
        >
          Sort by
          <svg className="-mr-1 tw-ml-1 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400 tw-group-hover:tw-text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-40 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-2xl tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
          <div className="tw-py-1" role="none">
            <button 
              onClick={() => handleSort('-capital')} 
              className={`tw-block tw-px-4 tw-py-2 tw-text-sm tw-w-full tw-text-left ${sortBy === '-capital' ? 'tw-bg-gray-100 tw-text-[#438a5e] tw-font-medium' : 'tw-text-gray-700'} hover:tw-bg-gray-50`}
            >
              Biggest capital
            </button>
            <button 
              onClick={() => handleSort('number_of_employees')} 
              className={`tw-block tw-px-4 tw-py-2 tw-text-sm tw-w-full tw-text-left ${sortBy === 'number_of_employees' ? 'tw-bg-gray-100 tw-text-[#438a5e] tw-font-medium' : 'tw-text-gray-700'} hover:tw-bg-gray-50`}
            >
              Number of employees
            </button>
            <button 
              onClick={() => handleSort('-registration_date')} 
              className={`tw-block tw-px-4 tw-py-2 tw-text-sm tw-w-full tw-text-left ${sortBy === '-registration_date' ? 'tw-bg-gray-100 tw-text-[#438a5e] tw-font-medium' : 'tw-text-gray-700'} hover:tw-bg-gray-50`}
            >
              Newest first
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortDropdown;
