import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Mail, Phone, Building, MapPin, Calendar, Shield, Key, Activity, Package, Clock, Database } from 'lucide-react';
import map from "../assets/images/ks.png";
import logo from "../assets/images/KSeco.png";

interface UserProfile {
  name: string;
  email: string;
  phone: string;
  company: string;
  address: string;
  joinDate: string;
  subscriptionPlan: string;
  lastLogin: string;
  apiCalls: number;
  remainingCalls: number;
  totalPackets: number;
  apiKeys: Array<{
    id: string;
    name: string;
    key: string;
    created: string;
    lastUsed: string;
    status: 'active' | 'expired';
  }>;
}

const MyAccount: React.FC = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState<UserProfile>({
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '+383 44 123 456',
    company: 'Tech Solutions LLC',
    address: 'Pristina, Kosovo',
    joinDate: 'January 15, 2024',
    subscriptionPlan: 'Professional',
    lastLogin: 'Today at 10:30 AM',
    apiCalls: 1234,
    remainingCalls: 8766,
    totalPackets: 3,
    apiKeys: [
      {
        id: '1',
        name: 'Production API Key',
        key: 'ks_prod_xxxxxxxxxxxx',
        created: '2024-01-15',
        lastUsed: '2024-02-20',
        status: 'active'
      },
      {
        id: '2',
        name: 'Development API Key',
        key: 'ks_dev_xxxxxxxxxxxx',
        created: '2024-01-15',
        lastUsed: '2024-02-19',
        status: 'active'
      }
    ]
  });

  const [newPassword, setNewPassword] = useState({
    current: '',
    new: '',
    confirm: ''
  });

  const StatCard = ({ icon: Icon, label, value }: { icon: any, label: string, value: string | number }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="tw-bg-white/90 tw-rounded-xl tw-p-6 tw-shadow-lg tw-flex tw-items-center tw-gap-4 hover:tw-bg-white/95 tw-transition-all"
    >
      <div className="tw-bg-hazel-green/10 tw-p-3 tw-rounded-lg">
        <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '24px', height: '24px' }}>
          <Icon 
            className="tw-text-hazel-green"
            style={{
              width: '24px',
              height: '24px',
              display: 'block',
              margin: 'auto'
            }}
          />
        </div>
      </div>
      <div>
        <p className="tw-text-gray-600 tw-text-sm">{label}</p>
        <p className="tw-text-gray-900 tw-font-semibold tw-text-lg">{value}</p>
      </div>
    </motion.div>
  );

  const PackageCard = ({ name, usagePercent }: { name: string, usagePercent: number }) => (
    <div className="tw-bg-white/90 tw-rounded-2xl tw-p-8 tw-shadow-lg">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h3 className="tw-font-medium tw-text-gray-900">{name}</h3>
        <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
          <Package 
            className="tw-text-hazel-green"
            style={{
              width: '20px',
              height: '20px',
              display: 'block',
              margin: 'auto'
            }}
          />
        </div>
      </div>
      <div className="tw-relative tw-pt-1">
        <div className="tw-overflow-hidden tw-h-2 tw-mb-4 tw-text-xs tw-flex tw-rounded tw-bg-gray-200">
          <div
            style={{ width: `${usagePercent}%` }}
            className="tw-shadow-none tw-flex tw-flex-col tw-text-center tw-whitespace-nowrap tw-text-white tw-justify-center tw-bg-hazel-green"
          />
        </div>
        <div className="tw-flex tw-justify-between tw-text-xs tw-text-gray-600">
          <span>Usage</span>
          <span>{usagePercent}%</span>
        </div>
      </div>
    </div>
  );

  const handlePasswordChange = (e: React.FormEvent) => {
    e.preventDefault();
    // Implement password change logic
    console.log('Password change requested');
  };

  const handleProfileUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    setIsEditing(false);
    // Implement profile update logic
    console.log('Profile update requested');
  };

  return (
    <div className="tw-min-h-screen tw-py-12 tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-relative">
      <div className="tw-absolute tw-inset-0 tw-z-0">
        <img src={map} alt="Map" className="tw-w-full tw-h-full tw-object-cover tw-opacity-5" />
      </div>
      
      <div className="tw-relative tw-z-10 tw-max-w-7xl tw-mx-auto tw-space-y-12">
        {/* Banner Section */}
        <div className="tw-relative tw-bg-gradient-to-r tw-from-[rgb(19,203,63)] tw-to-[rgb(67,138,94)] tw-text-white tw-py-10">
          <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
            <img src={map} alt="Kosovo Map" className="tw-w-full tw-h-full tw-object-cover tw-opacity-10" />
          </div>
          <div className="tw-container tw-mx-auto tw-px-4 tw-max-w-6xl tw-relative tw-z-10">
            <div className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex-shrink-0">
                <img src={logo} alt="KSeco Logo" className="tw-h-[200px] tw-w-auto" />
              </div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="tw-text-center tw-flex-grow"
              >
                <h1 className="tw-text-4xl tw-font-bold tw-mb-4">My Account</h1>
                <p className="tw-text-lg text-white tw-opacity-90">Manage your profile and monitor your usage</p>
              </motion.div>
              <div className="tw-flex-shrink-0 tw-w-[60px]"></div>
            </div>
          </div>
        </div>

        {/* Statistics Overview */}
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-6 tw-mt-12">
          <StatCard icon={Activity} label="API Calls Made" value={profile.apiCalls} />
          <StatCard icon={Clock} label="Remaining Calls" value={profile.remainingCalls} />
          <StatCard icon={Database} label="Total Packets" value={profile.totalPackets} />
          <StatCard icon={Package} label="Active Plan" value={profile.subscriptionPlan} />
        </div>

        {/* Main Content */}
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-8">
          {/* Left Column - Profile & Security */}
          <div className="lg:tw-col-span-2">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="tw-bg-white/90 tw-rounded-2xl tw-p-8 tw-shadow-lg"
            >
              {/* Tabs */}
              <div className="tw-flex tw-border-b tw-border-gray-200">
                <button
                  onClick={() => setActiveTab('profile')}
                  className={`tw-px-6 tw-py-3 tw-font-medium ${
                    activeTab === 'profile'
                      ? 'tw-text-hazel-green tw-border-b-2 tw-border-hazel-green'
                      : 'tw-text-gray-500 hover:tw-text-gray-700'
                  }`}
                >
                  Profile
                </button>
                <button
                  onClick={() => setActiveTab('security')}
                  className={`tw-px-6 tw-py-3 tw-font-medium ${
                    activeTab === 'security'
                      ? 'tw-text-hazel-green tw-border-b-2 tw-border-hazel-green'
                      : 'tw-text-gray-500 hover:tw-text-gray-700'
                  }`}
                >
                  Security
                </button>
                <button
                  onClick={() => setActiveTab('api')}
                  className={`tw-px-6 tw-py-3 tw-font-medium ${
                    activeTab === 'api'
                      ? 'tw-text-hazel-green tw-border-b-2 tw-border-hazel-green'
                      : 'tw-text-gray-500 hover:tw-text-gray-700'
                  }`}
                >
                  API Keys
                </button>
                <button
                  onClick={() => setActiveTab('billing')}
                  className={`tw-px-6 tw-py-3 tw-font-medium ${
                    activeTab === 'billing'
                      ? 'tw-text-hazel-green tw-border-b-2 tw-border-hazel-green'
                      : 'tw-text-gray-500 hover:tw-text-gray-700'
                  }`}
                >
                  Billing
                </button>
              </div>

              {/* Profile Section */}
              {activeTab === 'profile' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="tw-p-8"
                >
                  <form onSubmit={handleProfileUpdate}>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
                      <div className="tw-relative">
                        <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                          <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                            <User 
                              className="tw-text-gray-400"
                              style={{
                                width: '20px',
                                height: '20px',
                                display: 'block',
                                margin: 'auto'
                              }}
                            />
                          </div>
                        </div>
                        <input
                          type="text"
                          value={profile.name}
                          disabled={!isEditing}
                          className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2.5 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-hazel-green focus:tw-border-transparent"
                          placeholder="Full Name"
                        />
                      </div>

                      <div className="tw-relative">
                        <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                          <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                            <Mail 
                              className="tw-text-gray-400"
                              style={{
                                width: '20px',
                                height: '20px',
                                display: 'block',
                                margin: 'auto'
                              }}
                            />
                          </div>
                        </div>
                        <input
                          type="email"
                          value={profile.email}
                          onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                          disabled={!isEditing}
                          className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2.5 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-hazel-green focus:tw-border-transparent"
                          placeholder="Email Address"
                        />
                      </div>

                      <div className="tw-relative">
                        <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                          <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                            <Phone 
                              className="tw-text-gray-400"
                              style={{
                                width: '20px',
                                height: '20px',
                                display: 'block',
                                margin: 'auto'
                              }}
                            />
                          </div>
                        </div>
                        <input
                          type="tel"
                          value={profile.phone}
                          onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
                          disabled={!isEditing}
                          className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2.5 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-hazel-green focus:tw-border-transparent"
                          placeholder="Phone Number"
                        />
                      </div>

                      <div className="tw-relative">
                        <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                          <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                            <Building 
                              className="tw-text-gray-400"
                              style={{
                                width: '20px',
                                height: '20px',
                                display: 'block',
                                margin: 'auto'
                              }}
                            />
                          </div>
                        </div>
                        <input
                          type="text"
                          value={profile.company}
                          onChange={(e) => setProfile({ ...profile, company: e.target.value })}
                          disabled={!isEditing}
                          className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2.5 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-hazel-green focus:tw-border-transparent"
                          placeholder="Company Name"
                        />
                      </div>

                      <div className="tw-relative">
                        <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                          <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                            <MapPin 
                              className="tw-text-gray-400"
                              style={{
                                width: '20px',
                                height: '20px',
                                display: 'block',
                                margin: 'auto'
                              }}
                            />
                          </div>
                        </div>
                        <input
                          type="text"
                          value={profile.address}
                          onChange={(e) => setProfile({ ...profile, address: e.target.value })}
                          disabled={!isEditing}
                          className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2.5 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-hazel-green focus:tw-border-transparent"
                          placeholder="Address"
                        />
                      </div>
                    </div>

                    <div className="tw-mt-6 tw-flex tw-justify-end tw-gap-4">
                      {!isEditing ? (
                        <button
                          type="button"
                          onClick={() => setIsEditing(true)}
                          className="tw-px-6 tw-py-2 tw-bg-hazel-green tw-text-white tw-rounded-lg hover:tw-bg-hazel-green/90 tw-transition-all"
                        >
                          Edit Profile
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={() => setIsEditing(false)}
                            className="tw-px-6 tw-py-2 tw-border tw-border-gray-300 tw-rounded-lg hover:tw-bg-gray-50"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="tw-px-6 tw-py-2 tw-bg-hazel-green tw-text-white tw-rounded-lg hover:tw-bg-hazel-green/90"
                          >
                            Save Changes
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </motion.div>
              )}

              {/* Security Section */}
              {activeTab === 'security' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="tw-p-8"
                >
                  <form onSubmit={handlePasswordChange}>
                    <div className="tw-space-y-4">
                      <div>
                        <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-2">
                          <Key className="tw-inline tw-w-4 tw-h-4 tw-mr-2" />
                          Current Password
                        </label>
                        <input
                          type="password"
                          value={newPassword.current}
                          onChange={(e) => setNewPassword({ ...newPassword, current: e.target.value })}
                          className="tw-w-full tw-px-4 tw-py-2 tw-rounded-lg tw-border tw-border-gray-300 focus:tw-ring-2 focus:tw-ring-hazel-green focus:tw-border-transparent"
                        />
                      </div>

                      <div>
                        <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-2">
                          <Key className="tw-inline tw-w-4 tw-h-4 tw-mr-2" />
                          New Password
                        </label>
                        <input
                          type="password"
                          value={newPassword.new}
                          onChange={(e) => setNewPassword({ ...newPassword, new: e.target.value })}
                          className="tw-w-full tw-px-4 tw-py-2 tw-rounded-lg tw-border tw-border-gray-300 focus:tw-ring-2 focus:tw-ring-hazel-green focus:tw-border-transparent"
                        />
                      </div>

                      <div>
                        <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-2">
                          <Key className="tw-inline tw-w-4 tw-h-4 tw-mr-2" />
                          Confirm New Password
                        </label>
                        <input
                          type="password"
                          value={newPassword.confirm}
                          onChange={(e) => setNewPassword({ ...newPassword, confirm: e.target.value })}
                          className="tw-w-full tw-px-4 tw-py-2 tw-rounded-lg tw-border tw-border-gray-300 focus:tw-ring-2 focus:tw-ring-hazel-green focus:tw-border-transparent"
                        />
                      </div>
                    </div>

                    <div className="tw-mt-6">
                      <button
                        type="submit"
                        className="tw-w-full tw-px-6 tw-py-2 tw-bg-hazel-green tw-text-white tw-rounded-lg hover:tw-bg-hazel-green/90"
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </motion.div>
              )}

              {/* API Keys Tab */}
              {activeTab === 'api' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="tw-p-8"
                >
                  <div className="tw-flex tw-justify-between tw-items-center tw-mb-6">
                    <h2 className="tw-text-xl tw-font-semibold">API Keys</h2>
                    <button
                      className="tw-bg-hazel-green tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-hazel-green/90 tw-transition-colors"
                      onClick={() => {/* Handle new API key generation */}}
                    >
                      Generate New Key
                    </button>
                  </div>
                  
                  <div className="tw-space-y-4">
                    {profile.apiKeys.map(key => (
                      <div key={key.id} className="tw-bg-gray-50 tw-rounded-lg tw-p-4">
                        <div className="tw-flex tw-justify-between tw-items-start">
                          <div>
                            <h3 className="tw-font-medium tw-text-gray-900">{key.name}</h3>
                            <p className="tw-text-sm tw-text-gray-500">Created on {key.created}</p>
                          </div>
                          <span className={`tw-px-2 tw-py-1 tw-text-xs tw-rounded-full ${
                            key.status === 'active' 
                              ? 'tw-bg-green-100 tw-text-green-800' 
                              : 'tw-bg-red-100 tw-text-red-800'
                          }`}>
                            {key.status}
                          </span>
                        </div>
                        <div className="tw-mt-3 tw-flex tw-items-center tw-gap-2">
                          <code className="tw-bg-gray-100 tw-px-3 tw-py-1 tw-rounded tw-text-sm tw-font-mono">
                            {key.key}
                          </code>
                          <button
                            className="tw-text-gray-500 hover:tw-text-gray-700"
                            onClick={() => {/* Handle copy */}}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                              <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                            </svg>
                          </button>
                        </div>
                        <div className="tw-mt-2 tw-text-sm tw-text-gray-500">
                          Last used: {key.lastUsed}
                        </div>
                      </div>
                    ))}
                  </div>
                </motion.div>
              )}

              {/* Billing Tab */}
              {activeTab === 'billing' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="tw-p-8"
                >
                  <div className="tw-space-y-6">
                    {/* Current Plan */}
                    <div className="tw-bg-gray-50 tw-rounded-lg tw-p-6">
                      <h3 className="tw-text-lg tw-font-semibold tw-mb-4">Current Plan</h3>
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <div>
                          <p className="tw-text-2xl tw-font-bold tw-text-hazel-green">{profile.subscriptionPlan}</p>
                          <p className="tw-text-sm tw-text-gray-500">Billed monthly</p>
                        </div>
                        <button className="tw-text-hazel-green hover:tw-text-hazel-green/90 tw-font-medium">
                          Upgrade Plan
                        </button>
                      </div>
                    </div>

                    {/* Usage Overview */}
                    <div className="tw-bg-gray-50 tw-rounded-lg tw-p-6">
                      <h3 className="tw-text-lg tw-font-semibold tw-mb-4">Usage Overview</h3>
                      <div className="tw-space-y-4">
                        <div>
                          <div className="tw-flex tw-justify-between tw-mb-2">
                            <span className="tw-text-sm tw-text-gray-600">API Calls</span>
                            <span className="tw-text-sm tw-font-medium">{profile.apiCalls} / 10,000</span>
                          </div>
                          <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full">
                            <div 
                              className="tw-h-2 tw-bg-hazel-green tw-rounded-full"
                              style={{ width: `${(profile.apiCalls / 10000) * 100}%` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Payment Method */}
                    <div className="tw-bg-gray-50 tw-rounded-lg tw-p-6">
                      <h3 className="tw-text-lg tw-font-semibold tw-mb-4">Payment Method</h3>
                      <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-items-center tw-gap-3">
                          <div className="tw-bg-white tw-p-2 tw-rounded">
                            <svg className="tw-w-6 tw-h-6" viewBox="0 0 24 24" fill="none">
                              <rect x="3" y="5" width="18" height="14" rx="2" stroke="currentColor" strokeWidth="2"/>
                              <path d="M3 10H21" stroke="currentColor" strokeWidth="2"/>
                            </svg>
                          </div>
                          <div>
                            <p className="tw-font-medium">Visa ending in 4242</p>
                            <p className="tw-text-sm tw-text-gray-500">Expires 12/24</p>
                          </div>
                        </div>
                        <button className="tw-text-hazel-green hover:tw-text-hazel-green/90 tw-font-medium">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </motion.div>
          </div>

          {/* Right Column - Packages & Usage */}
          <div className="lg:tw-col-span-1">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="tw-space-y-6"
            >
              {/* Account Status */}
              <div className="tw-bg-white/90 tw-rounded-2xl tw-p-8 tw-shadow-lg">
                <h2 className="tw-text-lg tw-font-semibold tw-mb-4">Account Status</h2>
                <div className="tw-space-y-4">
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <span className="tw-text-gray-600">Member Since</span>
                    <span className="tw-font-medium">{profile.joinDate}</span>
                  </div>
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <span className="tw-text-gray-600">Subscription</span>
                    <span className="tw-font-medium">{profile.subscriptionPlan}</span>
                  </div>
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <span className="tw-text-gray-600">Last Login</span>
                    <span className="tw-font-medium">{profile.lastLogin}</span>
                  </div>
                </div>
              </div>

              {/* Active Packages */}
              <div className="tw-bg-white/90 tw-rounded-2xl tw-p-8 tw-shadow-lg">
                <h2 className="tw-text-lg tw-font-semibold tw-mb-4">Active Packages</h2>
                <div className="tw-space-y-4">
                  <PackageCard name="Basic API Package" usagePercent={75} />
                  <PackageCard name="Premium Features" usagePercent={30} />
                  <PackageCard name="Data Analytics" usagePercent={50} />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;