import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Lock, X, Eye, EyeOff } from "lucide-react";

const ResetPasswordModal = ({ isOpen, onClose, onLoginClick }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle reset password logic here
    if (formData.password === formData.confirmPassword) {
      // Reset successful, redirect to login
      onLoginClick();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="tw-bg-white tw-rounded-xl tw-p-8 tw-w-full tw-max-w-md tw-relative"
          >
            {/* Close Button */}
            <button
              onClick={onClose}
              className="tw-absolute tw-right-4 tw-top-4 tw-text-gray-500 hover:tw-text-gray-700"
            >
              <X size={20} />
            </button>

            <form onSubmit={handleSubmit} className="tw-space-y-6">
              <div className="tw-text-center">
                <h2 className="tw-text-2xl tw-font-bold tw-text-gray-900">
                  Reset Password
                </h2>
                <p className="tw-mt-2 tw-text-sm tw-text-gray-600">
                  Enter your new password below.
                </p>
              </div>

              {/* New Password Input */}
              <div className="tw-relative">
                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <Lock 
                      className="tw-text-gray-400"
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'block',
                        margin: 'auto'
                      }}
                    />
                  </div>
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  className="tw-block tw-w-full tw-pl-8 tw-pr-8 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                  placeholder="New password"
                  required
                />
                <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-2.5 tw-flex tw-items-center">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="tw-flex tw-items-center tw-justify-center"
                    >
                      {showPassword ? (
                        <EyeOff 
                          className="tw-text-gray-400 hover:tw-text-gray-600"
                          style={{
                            width: '16px',
                            height: '16px',
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      ) : (
                        <Eye 
                          className="tw-text-gray-400 hover:tw-text-gray-600"
                          style={{
                            width: '16px',
                            height: '16px',
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Confirm Password Input */}
              <div className="tw-relative">
                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <Lock 
                      className="tw-text-gray-400"
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'block',
                        margin: 'auto'
                      }}
                    />
                  </div>
                </div>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={formData.confirmPassword}
                  onChange={(e) =>
                    setFormData({ ...formData, confirmPassword: e.target.value })
                  }
                  className="tw-block tw-w-full tw-pl-8 tw-pr-8 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                  placeholder="Confirm new password"
                  required
                />
                <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-2.5 tw-flex tw-items-center">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <button
                      type="button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      className="tw-flex tw-items-center tw-justify-center"
                    >
                      {showConfirmPassword ? (
                        <EyeOff 
                          className="tw-text-gray-400 hover:tw-text-gray-600"
                          style={{
                            width: '16px',
                            height: '16px',
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      ) : (
                        <Eye 
                          className="tw-text-gray-400 hover:tw-text-gray-600"
                          style={{
                            width: '16px',
                            height: '16px',
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <motion.button
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                type="submit"
                className="tw-w-full tw-bg-green-600 tw-text-white tw-py-2.5 tw-rounded-lg tw-font-medium hover:tw-bg-green-700 tw-transition-colors tw-shadow-lg hover:tw-shadow-xl"
              >
                Reset Password
              </motion.button>

              {/* Back to Login Link */}
              <p className="tw-text-center tw-mt-6 tw-text-sm tw-text-gray-600">
                Remember your password?{" "}
                <button
                  type="button"
                  onClick={() => onLoginClick()}
                  className="tw-font-medium tw-text-green-600 hover:tw-text-green-700"
                >
                  Sign in
                </button>
              </p>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ResetPasswordModal;
