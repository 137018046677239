// src/api/filters.js
import axios from 'axios';

// const API_BASE_URL = 'http://localhost:8000/api'; 
const API_BASE_URL = 'https://backend.kseco.ch/api';


export const filterOptions = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/filter-options/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching filter options:', error);
        throw error;
    }
};

export const filterByCapitalRange = async (capitalRange) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/filter-by-capital/?capital_range=${capitalRange}`);
        return response.data;
    } catch (error) {
        console.error('Error filtering by capital range:', error);
        throw error;
    }
};

export const filterByCheckbox = async (filterParams) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/filter-by-checkbox/`, { params: filterParams });
        return response.data;
    } catch (error) {
        console.error('Error filtering by checkbox:', error);
        throw error;
    }
};

export const filterByMap = async (municipality) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/filter-by-map/?municipality=${municipality}`);
        return response.data;
    } catch (error) {
        console.error('Error filtering by map:', error);
        throw error;
    }
};
