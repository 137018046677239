import React from 'react';
import styles from './BusinessTable.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const tableVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
        opacity: 1, 
        x: 0,
        transition: { 
            type: "spring",
            stiffness: 100,
            duration: 0.5 
        }
    },
    exit: { 
        opacity: 0, 
        x: 20,
        transition: { 
            duration: 0.3 
        }
    }
};

const BusinessTable = ({ businesses }) => {
    return (
        <div className="lg:col-span-3">
            <div id="searchResultsContainer">
                <div id="business-table">
                    <AnimatePresence mode="wait">
                        <motion.table
                            key={businesses?.length || 'empty'} // Force re-render on data change
                            variants={tableVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            id="searchResultsTable" 
                            className={`${styles.table} table table-bordered table-striped`}
                        >
                            <thead>
                                <tr>
                                    <th scope="col">EMRI I BIZNESIT</th>
                                    <th scope="col">TIPI I BIZNESIT</th>
                                    <th scope="col">NUMRI UNIK</th>
                                    <th scope="col">KOMUNA</th>
                                    <th scope="col">DATA E REGJISTRIMIT</th>
                                    <th scope="col">NUMRI I PUNETOREVE</th>
                                    <th scope="col">NUMRI I TELEFONIT</th>
                                    <th scope="col">KAPITALI</th>
                                    <th scope="col">STATUSI</th>
                                </tr>
                            </thead>
                            <motion.tbody
                                id="searchFilteredTableBody"
                                variants={{
                                    visible: {
                                        transition: {
                                            staggerChildren: 0.05
                                        }
                                    }
                                }}
                            >
                                {businesses && businesses.length > 0 ? (
                                    businesses.map((business, index) => (
                                        <motion.tr
                                            key={business.id}
                                            className="tw-border-b"
                                            variants={{
                                                hidden: { opacity: 0, y: 20 },
                                                visible: { 
                                                    opacity: 1, 
                                                    y: 0,
                                                    transition: {
                                                        type: "spring",
                                                        stiffness: 100,
                                                        delay: index * 0.05
                                                    }
                                                }
                                            }}
                                        >
                                            <td className="tw-px-4 tw-py-2">
                                                <Link 
                                                    to={`/details?id=${business.id}`}
                                                    className="tw-text-[#438a5e] hover:tw-text-[#2f6c4a]"
                                                >
                                                    {business.business_name}
                                                </Link>
                                            </td>
                                            <td className="tw-px-4 tw-py-2">{business.business_type}</td>
                                            <td className="tw-px-4 tw-py-2">{business.unique_identifier}</td>
                                            <td className="tw-px-4 tw-py-2">{business.municipality}</td>
                                            <td className="tw-px-4 tw-py-2">{business.registration_date}</td>
                                            <td className="tw-px-4 tw-py-2">{business.number_of_employees}</td>
                                            <td className="tw-px-4 tw-py-2">{business.phone}</td>
                                            <td className="tw-px-4 tw-py-2">{business.capital}</td>
                                            <td className="tw-px-4 tw-py-2">{business.status_in_arbk}</td>
                                        </motion.tr>
                                    ))
                                ) : (
                                    <motion.tr
                                        variants={{
                                            hidden: { opacity: 0 },
                                            visible: { opacity: 1 }
                                        }}
                                    >
                                        <td colSpan="9" className="tw-text-center">No businesses found</td>
                                    </motion.tr>
                                )}
                            </motion.tbody>
                        </motion.table>
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
};

export default BusinessTable;
