import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Mail, Lock, X, Eye, EyeOff, User } from "lucide-react";
import authAPI from '../api/auth';

const SignupModal = ({ isOpen, onClose, onLoginClick }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.username || !formData.password || !formData.confirmPassword) {
      setError("All fields are required");
      return;
    }

    // Validate passwords match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      // Register the user
      await authAPI.register({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        username: formData.username,
        password: formData.password,
        password2: formData.confirmPassword
      });
      
      // After successful registration, log the user in
      await authAPI.login(formData.email, formData.password);
      onClose(); // Close the modal
      window.location.reload(); // Refresh the page to update the UI
    } catch (error) {
      console.error('Registration error:', error);
      if (error.first_name) setError(error.first_name[0]);
      else if (error.last_name) setError(error.last_name[0]);
      else if (error.email) setError(error.email[0]);
      else if (error.username) setError(error.username[0]);
      else if (error.password) setError(error.password[0]);
      else setError("Registration failed. Please try again.");
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="tw-fixed tw-inset-0 tw-z-[9999] tw-flex tw-items-center tw-justify-center">
          {/* Backdrop */}
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            onClick={onClose}
            className="tw-fixed tw-inset-0 tw-bg-black/70 tw-backdrop-blur-sm"
          />

          {/* Modal */}
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={modalVariants}
            transition={{ type: "spring", duration: 0.5 }}
            className="tw-bg-white tw-w-full tw-max-w-md tw-mx-4 tw-rounded-2xl tw-shadow-2xl tw-relative tw-z-[10000]"
          >
            {/* Close button */}
            <div className="tw-absolute tw-right-0 tw-top-0 tw-pt-4 tw-pr-4">
              <button
                type="button"
                onClick={onClose}
                className="tw-flex tw-items-center tw-justify-center"
                style={{ width: "24px", height: "24px" }}
              >
                <X
                  className="tw-text-gray-400 hover:tw-text-gray-600"
                  style={{
                    width: "24px",
                    height: "24px",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </button>
            </div>

            {/* Header */}
            <div className="tw-px-8 tw-pt-8 tw-pb-4">
              <h2 className="tw-text-2xl tw-font-bold tw-text-gray-900">
                Create an account
              </h2>
              <p className="tw-text-gray-600 tw-mt-1">
                Sign up to get started with our platform
              </p>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="tw-p-6 tw-pt-4 tw-space-y-4">
              {/* First Name and Last Name Fields */}
              <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <div className="tw-relative">
                  <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                    <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                      <User 
                        className="tw-text-gray-400"
                        style={{
                          width: '16px',
                          height: '16px',
                          display: 'block',
                          margin: 'auto'
                        }}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="tw-block tw-w-full tw-pl-8 tw-pr-3 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="tw-relative">
                  <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                    <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                      <User 
                        className="tw-text-gray-400"
                        style={{
                          width: '16px',
                          height: '16px',
                          display: 'block',
                          margin: 'auto'
                        }}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="tw-block tw-w-full tw-pl-8 tw-pr-3 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>

              {/* Username Field */}
              <div className="tw-relative">
                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <User 
                      className="tw-text-gray-400"
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'block',
                        margin: 'auto'
                      }}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="tw-block tw-w-full tw-pl-8 tw-pr-3 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                  placeholder="Username"
                  required
                />
              </div>

              {/* Email Field */}
              <div className="tw-relative">
                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <Mail 
                      className="tw-text-gray-400"
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'block',
                        margin: 'auto'
                      }}
                    />
                  </div>
                </div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="tw-block tw-w-full tw-pl-8 tw-pr-3 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                  placeholder="Email"
                  required
                />
              </div>

              {/* Password Field */}
              <div className="tw-relative">
                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <Lock 
                      className="tw-text-gray-400"
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'block',
                        margin: 'auto'
                      }}
                    />
                  </div>
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="tw-block tw-w-full tw-pl-8 tw-pr-8 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                  placeholder="Password"
                  required
                />
                <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-2.5 tw-flex tw-items-center">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="tw-flex tw-items-center tw-justify-center"
                    >
                      {showPassword ? (
                        <EyeOff 
                          className="tw-text-gray-400 hover:tw-text-gray-600"
                          style={{
                            width: '16px',
                            height: '16px',
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      ) : (
                        <Eye 
                          className="tw-text-gray-400 hover:tw-text-gray-600"
                          style={{
                            width: '16px',
                            height: '16px',
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Confirm Password Field */}
              <div className="tw-relative">
                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2.5 tw-flex tw-items-center tw-pointer-events-none">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <Lock 
                      className="tw-text-gray-400"
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'block',
                        margin: 'auto'
                      }}
                    />
                  </div>
                </div>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="tw-block tw-w-full tw-pl-8 tw-pr-8 tw-py-2 tw-text-sm tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                  placeholder="Confirm Password"
                  required
                />
                <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-2.5 tw-flex tw-items-center">
                  <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '16px', height: '16px' }}>
                    <button
                      type="button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      className="tw-flex tw-items-center tw-justify-center"
                    >
                      {showConfirmPassword ? (
                        <EyeOff 
                          className="tw-text-gray-400 hover:tw-text-gray-600"
                          style={{
                            width: '16px',
                            height: '16px',
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      ) : (
                        <Eye 
                          className="tw-text-gray-400 hover:tw-text-gray-600"
                          style={{
                            width: '16px',
                            height: '16px',
                            display: 'block',
                            margin: 'auto'
                          }}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Error message */}
              {error && (
                <p className="tw-text-red-600 tw-text-sm tw-mt-2">{error}</p>
              )}

              {/* Submit Button */}
              <motion.button
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                type="submit"
                className="tw-w-full tw-bg-green-600 tw-text-white tw-py-2.5 tw-rounded-lg tw-font-medium hover:tw-bg-green-700 tw-transition-colors tw-shadow-lg hover:tw-shadow-xl"
              >
                Sign up
              </motion.button>

              {/* Login Link */}
              <p className="tw-text-center tw-mt-6 tw-text-sm tw-text-gray-600">
                Already have an account?{" "}
                <button
                  type="button"
                  onClick={() => onLoginClick()}
                  className="tw-font-medium tw-text-green-600 hover:tw-text-green-700"
                >
                  Sign in
                </button>
              </p>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default SignupModal;
