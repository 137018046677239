import React from 'react';
import logo from "../assets/images/KSeco.png"
import LoginModal from './LoginModal';
import AuthIndicator from './AuthIndicator.tsx';
import { useAuth } from '../context/AuthContext';

const Header = ({ openLoginModal }) => {
    const { isAuthenticated, user, logout } = useAuth();

    const handleLogout = async () => {
        await logout();
        
    };

    return (
        <div>
            <div className="top-bar-area inc-pad bg-theme text-light tw-py-3">
                <div className="container tw-mx-auto tw-px-4">
                    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
                        <div className="tw-flex tw-items-center tw-space-x-6">
                            <div className="tw-flex tw-items-center tw-space-x-2">
                                <i className="fas fa-map-marker-alt tw-text-sm"></i>
                                <span>Kosova, Peje 30000</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-space-x-2">
                                <i className="fas fa-envelope-open tw-text-sm"></i>
                                <span>Info@gmail.com</span>
                            </div>
                        </div>
                        <div className="tw-flex tw-items-center tw-space-x-6">
                            <div className="tw-flex tw-items-center tw-space-x-2">
                                <i className="fas fa-clock tw-text-sm"></i>
                                <span>Office Hours: 8:00 AM – 7:45 PM</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-space-x-4">
                                <a href="#" className="tw-text-white hover:tw-text-gray-200"><i className="fab fa-facebook-f"></i></a>
                                <a href="#" className="tw-text-white hover:tw-text-gray-200"><i className="fab fa-twitter"></i></a>
                                <a href="#" className="tw-text-white hover:tw-text-gray-200"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <header id="home">
                <nav className="navbar navbar-default active-border navbar-sticky bootsnav tw-bg-white">
                    <div className="container tw-mx-auto tw-px-4 tw-flex tw-items-center tw-justify-between tw-h-[90px]">
                        <div className="navbar-header tw-flex tw-items-center">
                            <button type="button" className="navbar-toggle tw-mr-4" data-toggle="collapse" data-target="#navbar-menu">
                                <i className="fa fa-bars"></i>
                            </button>
                            <a className="navbar-brand" href="index.html">
                                <img src={logo} className="logo tw-h-[45px] tw-w-auto" alt="Logo" />
                            </a>
                        </div>
                        
                        <div className="collapse navbar-collapse !tw-flex !tw-items-center !tw-h-[90px]" id="navbar-menu">
                            <ul className="nav navbar-nav tw-flex tw-items-center tw-mx-auto tw-space-x-6 !tw-h-[90px]">
                                <li><a href="/" className="tw-text-gray-800 hover:tw-text-[#438a5e]">Home</a></li>
                                <li><a href="#" className="tw-text-gray-800 hover:tw-text-[#438a5e]">Company</a></li>
                                <li><a href="/services" className="tw-text-gray-800 hover:tw-text-[#438a5e]">Services</a></li>
                                <li><a href="/contact" className="tw-text-gray-800 hover:tw-text-[#438a5e]">Contact</a></li>
                                <li className="tw-relative tw-h-[90px]">
                                    <div className="tw-absolute tw-top-1/2 tw-left-0 tw--translate-y-1/2">
                                        {isAuthenticated ? (
                                            <AuthIndicator 
                                                user={{
                                                    name: user?.username || user?.email || 'User',
                                                    email: user?.email || '',
                                                    avatar: user?.avatar
                                                }} 
                                                onLogout={handleLogout}
                                            />
                                        ) : (
                                            <button 
                                                onClick={openLoginModal}
                                                className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-h-10 tw-px-5 tw-text-sm tw-font-medium tw-text-white tw-rounded-full tw-border-0 tw-outline-none tw-bg-gradient-to-r tw-from-[rgb(19,203,63)] tw-to-[rgb(67,138,94)] hover:tw-opacity-90 tw-transition-all tw-shadow-sm"
                                            >
                                                <i className="fa-regular fa-user tw-text-[18px]"></i>
                                                <span>Login</span>
                                            </button>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="attr-nav inc-border tw-flex tw-items-center tw-border-l tw-pl-6 tw-ml-auto">
                            <div className="contact tw-flex tw-items-center tw-space-x-3">
                                <i className="fas fa-phone tw-text-[#438a5e] tw-text-xl"></i>
                                <div>
                                    <p className="tw-text-sm tw-text-gray-500">Call Us Now</p>
                                    <h5 className="tw-text-base tw-font-semibold tw-text-gray-800">+123 456 7890</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <LoginModal isOpen={false} onClose={() => {}} />
        </div>
    );
};

export default Header;
