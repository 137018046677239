import React, { useState } from 'react';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Search submitted with query:', query); // Debug log
    if (query.trim()) {
      onSearch(query.trim());
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    if (!e.target.value.trim()) {
      onSearch(''); // Clear search when input is empty
    }
  };

  return (
    <form onSubmit={handleSubmit} className="tw-w-[400px] tw-flex tw-items-center tw-gap-3">
      <div className="tw-relative tw-flex-1">
        <input
          type="search"
          value={query}
          onChange={handleInputChange}
          className="tw-bg-white tw-shadow-sm tw-rounded-lg tw-border tw-border-gray-300 tw-py-2 tw-px-4 tw-w-full tw-pl-10 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#438a5e] focus:tw-border-transparent"
          placeholder="Search businesses..."
        />
        <span className="tw-absolute tw-left-3 tw-top-1/2 tw--translate-y-1/2">
          <svg className="tw-h-5 tw-w-5 tw-text-[#438a5e]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </span>
      </div>
      <button 
        type="submit"
        className="tw-bg-[#438a5e] hover:tw-bg-[#357048] tw-text-white tw-font-medium tw-py-2 tw-px-5 tw-rounded-lg tw-shadow-sm tw-transition-colors"
      >
        Search
      </button>
    </form>
  );
};

export default SearchBar;
