import React from 'react';
import { motion } from 'framer-motion';

const TableComponent = ({ companies = [], simplified = false }) => {
    if (!Array.isArray(companies)) {
        console.warn('TableComponent: companies is not an array', companies);
        return null;
    }

    if (companies.length === 0) {
        return (
            <div className="tw-text-center tw-py-4 tw-text-gray-500">
                No companies to display
            </div>
        );
    }

    const formatCapital = (capital) => {
        if (!capital || capital === "empty") return 'N/A';
        const num = parseFloat(capital);
        return num.toLocaleString('en-US', { 
            style: 'currency', 
            currency: 'EUR',
            minimumFractionDigits: 2
        });
    };

    return (
        <div className="tw-overflow-x-auto">
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th scope="col">EMRI I BIZNESIT</th>
                        <th scope="col">NUMRI UNIK</th>
                        <th scope="col">KOMUNA</th>
                        <th scope="col">DATA E REGJISTRIMIT</th>
                        <th scope="col">STATUSI</th>
                        {!simplified && (
                            <>
                                <th scope="col">ADRESA</th>
                                <th scope="col">LLOJI I BIZNESIT</th>
                                <th scope="col">KAPITALI</th>
                                <th scope="col">NR. PUNËTORËVE</th>
                                <th scope="col">TELEFONI</th>
                                <th scope="col">EMAIL</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {companies.map((company, index) => (
                        <motion.tr
                            key={company.id || index}
                            initial={{ opacity: 0, y: 20, filter: 'blur(10px)' }}
                            animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
                            transition={{ 
                                duration: 0.5,
                                delay: index * 0.1
                            }}
                        >
                            <td>{company.business_name || 'N/A'}</td>
                            <td>{company.unique_identifier || 'N/A'}</td>
                            <td>{company.municipality || 'N/A'}</td>
                            <td>{company.registration_date || 'N/A'}</td>
                            <td>{company.status_in_arbk || 'N/A'}</td>
                            {!simplified && (
                                <>
                                    <td>{company.address === 'empty' ? 'N/A' : company.address || 'N/A'}</td>
                                    <td>{company.business_type || 'N/A'}</td>
                                    <td>{formatCapital(company.capital)}</td>
                                    <td>{company.number_of_employees === 'empty' ? 'N/A' : company.number_of_employees || 'N/A'}</td>
                                    <td>{company.phone === 'empty' ? 'N/A' : company.phone || 'N/A'}</td>
                                    <td>{company.email === 'empty' ? 'N/A' : company.email || 'N/A'}</td>
                                </>
                            )}
                        </motion.tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableComponent;
