import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import SearchResults from '../components/SearchResults';
import FormComponent from '../components/FormComponent';
import TableComponent from '../components/TableComponent';
import LatestRegisteredCompanies from '../components/LatestRegisteredCompanies';
import KosovoMap from '../components/KosovoMap';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';
import WorkProcess from '../components/WorkProcess';
import LoginModal from '../components/LoginModal';
import SignUpModal from '../components/SignupModal';
import ForgotPasswordModal from '../components/ForgotPasswordModal';
import ResetPasswordModal from '../components/ResetPasswordModal';
import { useAuth } from '../context/AuthContext';

import { getLatestBusinesses } from '../api/latestBusinesses';
import { filterOptions } from '../api/filters';
import { filterByCheckboxServices } from '../api/filterByCheckBoxServices';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../api'; // Import the configured API instance
import axios from 'axios';

const Home = () => {
    const { authStateChanged, isAuthenticated } = useAuth();
    const [businesses, setBusinesses] = useState([]);
    const [statisticsData, setStatisticsData] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [latestCompanies, setLatestCompanies] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [filterOptionsState, setFilterOptionsState] = useState({
        municipalities: [],
        activities: [],
        statuses: [],
        businessTypes: [],
        businessNames: [],
        capitalRanges: [],
        uniqueIds: [],
        registrationDates: [],
        genders: [],
    });
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

    const openLoginModal = () => {
        setIsSignUpModalOpen(false);
        setIsForgotPasswordModalOpen(false);
        setIsResetPasswordModalOpen(false);
        setIsLoginModalOpen(true);
    };

    const openSignUpModal = () => {
        setIsLoginModalOpen(false);
        setIsForgotPasswordModalOpen(false);
        setIsResetPasswordModalOpen(false);
        setIsSignUpModalOpen(true);
    };

    const openForgotPasswordModal = () => {
        setIsLoginModalOpen(false);
        setIsSignUpModalOpen(false);
        setIsResetPasswordModalOpen(false);
        setIsForgotPasswordModalOpen(true);
    };

    const openResetPasswordModal = () => {
        setIsLoginModalOpen(false);
        setIsSignUpModalOpen(false);
        setIsForgotPasswordModalOpen(false);
        setIsResetPasswordModalOpen(true);
    };

    const closeModals = () => {
        setIsLoginModalOpen(false);
        setIsSignUpModalOpen(false);
        setIsForgotPasswordModalOpen(false);
        setIsResetPasswordModalOpen(false);
    };

    useEffect(() => {
        // Only fetch data if authenticated
        if (isAuthenticated) {
            fetchStatisticsData();
            fetchLatestCompanies();
            fetchFilterOptions();
        } else {
            // Clear data when not authenticated
            setStatisticsData(null);
            setLatestCompanies([]);
            setFilterOptionsState({
                municipalities: [],
                activities: [],
                statuses: [],
                businessTypes: [],
                businessNames: [],
                capitalRanges: [],
                uniqueIds: [],
                registrationDates: [],
                genders: [],
            });
        }
    }, [authStateChanged, isAuthenticated]); // Re-run when auth state changes

    const fetchStatisticsData = async () => {
        try {
            const response = await api.get('/api/business-counts/');
            setStatisticsData(response.data);
        } catch (error) {
            console.error('Error fetching statistics:', error);
        }
    };

    const fetchLatestCompanies = async () => {
        try {
            const data = await getLatestBusinesses();
            console.log('Latest businesses data:', data);
            if (Array.isArray(data)) {
                setLatestCompanies(data);
            } else if (data && Array.isArray(data.businesses)) {
                setLatestCompanies(data.businesses);
            } else {
                console.warn('Unexpected latest businesses data format:', data);
                setLatestCompanies([]);
            }
        } catch (error) {
            console.error('Error fetching latest companies:', error);
            setLatestCompanies([]);
        }
    };

    const fetchFilterOptions = async () => {
        try {
            const response = await api.get('/api/filter-options/');
            const data = response.data;
            console.log('Filter options response:', data);
            
            setFilterOptionsState({
                municipalities: data.municipalities_options || [],
                activities: data.activities_options || [],
                statuses: data.status_options || [],
                businessTypes: data.business_type_options || [],
                businessNames: data.business_name_options || [],
                capitalRanges: data.capital_range_options || [],
                uniqueIds: data.unique_identifier_options || [],
                registrationDates: data.registration_date_options || [],
                genders: data.gender_options || []
            });
        } catch (error) {
            console.error('Error fetching filter options:', error);
        }
    };

    const handleSearchResults = (results) => {
        console.log('Search results received:', results);
        if (!results) {
            console.warn('Search results are undefined');
            setSearchResults([]);
            setShowResults(false);
            return;
        }
        // Ensure we're setting the results in the correct format
        setSearchResults(Array.isArray(results) ? results : [results]);
        setShowResults(true);
        setCompanies([]); // Clear filter results when searching

        // Scroll to results after a short delay
        setTimeout(() => {
            const resultsElement = document.getElementById('searchResults');
            if (resultsElement) {
                resultsElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    const handleFilterSubmit = async (filters) => {
        try {
            const response = await filterByCheckboxServices(filters);
            if (response && response.businesses) {
                setCompanies(response.businesses);
                setSearchResults([]); // Clear search results when filtering
                setShowResults(true);
            }
        } catch (error) {
            console.error("Error fetching filtered data:", error);
        }
    };

    return (
        <div className="home-container">
            <Header openLoginModal={openLoginModal} />
            <Banner statisticsData={statisticsData} onSearchResults={handleSearchResults} />
            {/* Remove Statistics component since it's now part of Banner */}
            
            {/* Search Results Section */}
            <AnimatePresence>
                {searchResults.length > 0 && (
                    <motion.div
                        id="searchResults"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5, type: "spring", stiffness: 100 }}
                        className="tw-container tw-mx-auto tw-px-4 tw-py-8"
                    >
                        <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-6">
                            <h2 className="tw-text-xl tw-font-semibold tw-mb-4">
                                Search Results ({searchResults.length} {searchResults.length === 1 ? 'business' : 'businesses'} found)
                            </h2>
                            <TableComponent 
                                companies={searchResults}
                                simplified={false}
                            />
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Form Component for Filters */}
            <FormComponent
                municipalities={filterOptionsState.municipalities}
                activities={filterOptionsState.activities}
                statuses={filterOptionsState.statuses}
                businessTypes={filterOptionsState.businessTypes}
                businessNames={filterOptionsState.businessNames}
                capitalRanges={filterOptionsState.capitalRanges}
                uniqueIds={filterOptionsState.uniqueIds}
                registrationDates={filterOptionsState.registrationDates}
                genders={filterOptionsState.genders}
                onSubmit={handleFilterSubmit}
            />

            {/* Filter Results Section */}
            {companies.length > 0 && (
                <div className="tw-container tw-mx-auto tw-px-4 tw-py-8">
                    <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-6">
                        <h2 className="tw-text-xl tw-font-semibold tw-mb-4">
                            Filter Results ({companies.length} {companies.length === 1 ? 'business' : 'businesses'} found)
                        </h2>
                        <TableComponent businesses={[]} companies={companies} simplified={true} />
                    </div>
                </div>
            )}

            <LatestRegisteredCompanies companies={latestCompanies} />
            <KosovoMap />
            <Features />
            <WorkProcess />
            <Pricing />
            <Footer />

            {/* Modals */}
            <LoginModal 
                isOpen={isLoginModalOpen} 
                onClose={closeModals}
                onSignUpClick={() => openSignUpModal()}
                onForgotPasswordClick={() => openForgotPasswordModal()}
            />
            <SignUpModal 
                isOpen={isSignUpModalOpen} 
                onClose={closeModals}
                onLoginClick={() => openLoginModal()}
            />
            <ForgotPasswordModal 
                isOpen={isForgotPasswordModalOpen} 
                onClose={closeModals}
                onLoginClick={() => openLoginModal()}
                onResetPasswordClick={() => openResetPasswordModal()}
            />
            <ResetPasswordModal 
                isOpen={isResetPasswordModalOpen} 
                onClose={closeModals}
                onLoginClick={() => openLoginModal()}
            />
        </div>
    );
};

export default Home;
