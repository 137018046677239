import React, { useState } from 'react';
import { Mail, Lock, Eye, EyeOff} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { AnimatePresence, motion } from 'framer-motion';
import { X } from 'lucide-react';

const LoginModal = ({ isOpen, onClose, onSignUpClick, onForgotPasswordClick }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState(null);
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        
        try {
            const success = await login(email, password);
            if (success) {
                onClose(); // Close the modal after successful login
            } else {
                setError('Login failed. Please check your credentials.');
            }
        } catch (error) {
            console.error('Login error:', error);
            if (error.non_field_errors) {
                setError(error.non_field_errors[0]);
            } else if (error.detail) {
                setError(error.detail);
            } else if (typeof error === 'string') {
                setError(error);
            } else {
                setError('Login failed. Please check your credentials.');
            }
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <div className="tw-fixed tw-inset-0 tw-z-[9999] tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ duration: 0.2 }}
                        className="tw-bg-white tw-rounded-xl tw-p-8 tw-w-full tw-max-w-md tw-relative"
                    >
                        {/* Close Button */}
                        <button
                            onClick={onClose}
                            className="tw-absolute tw-right-4 tw-top-4 tw-text-gray-500 hover:tw-text-gray-700"
                        >
                            <X size={20} />
                        </button>

                        <form onSubmit={handleSubmit} className="tw-space-y-6">
                            <div className="tw-text-center">
                                <h2 className="tw-text-2xl tw-font-bold tw-text-gray-900">
                                    Welcome Back
                                </h2>
                                <p className="tw-mt-2 tw-text-sm tw-text-gray-600">
                                    Please sign in to your account
                                </p>
                            </div>

                            {/* Email Input */}
                            <div className="tw-relative">
                                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                                    <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                                        <Mail 
                                            className="tw-text-gray-400"
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                display: 'block',
                                                margin: 'auto'
                                            }}
                                        />
                                    </div>
                                </div>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2.5 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>

                            {/* Password Input */}
                            <div className="tw-relative">
                                <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                                    <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                                        <Lock 
                                            className="tw-text-gray-400"
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                display: 'block',
                                                margin: 'auto'
                                            }}
                                        />
                                    </div>
                                </div>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="tw-block tw-w-full tw-pl-10 tw-pr-10 tw-py-2.5 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-ring-2 focus:tw-ring-green-600 focus:tw-border-transparent"
                                    placeholder="Enter your password"
                                    required
                                />
                                <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center">
                                    <div className="tw-flex tw-items-center tw-justify-center" style={{ width: '20px', height: '20px' }}>
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="tw-flex tw-items-center tw-justify-center"
                                        >
                                            {showPassword ? (
                                                <EyeOff 
                                                    className="tw-text-gray-400 hover:tw-text-gray-600"
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        display: 'block',
                                                        margin: 'auto'
                                                    }}
                                                />
                                            ) : (
                                                <Eye 
                                                    className="tw-text-gray-400 hover:tw-text-gray-600"
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        display: 'block',
                                                        margin: 'auto'
                                                    }}
                                                />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Remember Me and Forgot Password */}
                            <div className="tw-flex tw-items-center tw-justify-between">
                                <div className="tw-flex tw-items-center">
                                    <input
                                        type="checkbox"
                                        id="remember-me"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                        className="tw-h-4 tw-w-4 tw-text-green-600 focus:tw-ring-green-500 tw-border-gray-300 tw-rounded"
                                    />
                                    <label
                                        htmlFor="remember-me"
                                        className="tw-ml-2 tw-block tw-text-sm tw-text-gray-900"
                                    >
                                        Remember me
                                    </label>
                                </div>
                            </div>

                            {/* Submit Button */}
                            <motion.button
                                whileHover={{ scale: 1.01 }}
                                whileTap={{ scale: 0.99 }}
                                type="submit"
                                className="tw-w-full tw-bg-green-600 tw-text-white tw-py-2.5 tw-rounded-lg tw-font-medium hover:tw-bg-green-700 tw-transition-colors tw-shadow-lg hover:tw-shadow-xl"
                            >
                                Sign in
                            </motion.button>

                            {/* Forgot Password Link */}
                            <div className="tw-text-center tw-mt-4">
                                <button
                                    type="button"
                                    onClick={onForgotPasswordClick}
                                    className="tw-text-sm tw-text-green-600 hover:tw-text-green-700"
                                >
                                    Forgot your password?
                                </button>
                            </div>

                            {/* Sign Up Link */}
                            <p className="tw-text-center tw-mt-6 tw-text-sm tw-text-gray-600">
                                Don't have an account?{" "}
                                <button
                                    type="button"
                                    onClick={onSignUpClick}
                                    className="tw-font-medium tw-text-green-600 hover:tw-text-green-700"
                                >
                                    Sign up
                                </button>
                            </p>
                            {error && (
                                <p className="tw-text-center tw-mt-4 tw-text-sm tw-text-red-600">
                                    {error}
                                </p>
                            )}
                        </form>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default LoginModal;