// Banner.jsx
import React, { useState } from 'react';
import map from "../assets/images/ks.png";
import axios from 'axios';
import CountUp from 'react-countup';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';

const StatCard = ({ value, label, delay }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ 
            scale: 1.15,
            transition: { duration: 0.3, ease: "easeOut" }
        }}
        transition={{ duration: 0.5, delay }}
        className="tw-bg-white/95 tw-rounded-xl tw-p-6 tw-shadow-lg tw-text-center tw-transform tw-transition-all tw-duration-300 hover:tw-shadow-2xl hover:tw-bg-white hover:-tw-translate-y-2 tw-cursor-pointer"
    >
        <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: delay + 0.2 }}
            className="tw-text-4xl tw-font-bold tw-text-hazel-green tw-mb-2"
        >
            <CountUp 
                end={value} 
                duration={2} 
                separator="," 
            />
        </motion.div>
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: delay + 0.4 }}
            className="tw-text-gray-600 tw-mt-2 tw-text-lg tw-font-medium"
        >
            {label}
        </motion.div>
    </motion.div>
);

const Banner = ({ statisticsData, onSearchResults }) => {
    const { isAuthenticated } = useAuth();
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = async (e) => {
        e.preventDefault();
        
        try {
            const response = await axios.get(`http://backend.kseco.ch/api/search-business/`, {
                params: {
                    search_query: searchQuery
                }
            });

            const data = response.data;
            console.log("Search response:", data);
            
            if (data && data.businesses && data.businesses.length > 0) {
                onSearchResults(data.businesses);
            } else {
                onSearchResults([]);
                alert('No businesses found matching your search.');
            }
        } catch (error) {
            console.error('Search error:', error);
            alert('Error searching for businesses. Please try again.');
        }
    };

    const stats = {
        numberOfCompanies: statisticsData?.number_of_companies || 0,
        numberOfActiveCompanies: statisticsData?.number_of_active_companies || 0,
        numberOfBankruptCompanies: statisticsData?.number_of_bankrupt_companies || 0,
        todayPublications: statisticsData?.today_publications || 0
    };

    return (
        <div className="banner-area auto-height text-color inc-shape baneri relative overflow-hidden">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-lg">
                            <h3 className="tw-text-gray-800 tw-font-semibold tw-text-2xl tw-mb-4">Search Companies</h3>
                            <div className="tw-space-y-4">
                                <div className="tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-md">
                                    <span className="fa fa-search tw-hazel-green tw-py-2 tw-px-3" aria-hidden="true"></span>
                                    <form onSubmit={handleSearch} className="tw-flex tw-grow">
                                        <input
                                            type="text"
                                            className="tw-flex-1 tw-rounded-l-md tw-border-none tw-bg-gray-100 tw-text-gray-800 tw-py-2 tw-pl-10 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-green-goose"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            placeholder="Search"
                                            aria-label="Search companies"
                                        />
                                        <button
                                            type="submit"
                                            className="tw-bg-hazel-green tw-text-white tw-font-semibold tw-py-2 tw-px-4 tw-rounded-r-md hover:tw-bg-green-goose transition duration-300"
                                        >
                                            Search
                                        </button>
                                    </form>
                                </div>
                                <button className="tw-bg-hazel-green tw-border-none tw-text-white tw-py-2 tw-px-4 tw-rounded-md transition duration-300 hover:tw-bg-green-goose">
                                    <i className="fa-solid fa-filter text-white"></i> Advanced Filters
                                </button>
                            </div>
                            <p className="tw-text-gray-600 tw-mt-5">
                                Affixed pretend account ten natural. Need eat week even yet that. Incommode delighted he resolving sportsmen do in listening.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6 thumb position-relative">
                        <img className="wow" src={map} alt="Thumb" style={{ opacity: 0.2 }} />
                        <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-mt-[-25%]">
                            <div className="tw-w-full tw-max-w-3xl">
                                <motion.div 
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    className="tw-grid tw-grid-cols-2 tw-gap-6"
                                >
                                    <StatCard
                                        value={stats.numberOfCompanies}
                                        label="Total Companies"
                                        delay={0}
                                    />
                                    <StatCard
                                        value={stats.numberOfActiveCompanies}
                                        label="Active Companies"
                                        delay={0.2}
                                    />
                                    <StatCard
                                        value={stats.numberOfBankruptCompanies}
                                        label="Bankrupt Companies"
                                        delay={0.4}
                                    />
                                    <StatCard
                                        value={stats.todayPublications}
                                        label="Today's Publications"
                                        delay={0.6}
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
