/* global $ */
import React, { useEffect } from 'react';

const LatestRegisteredCompanies = ({ companies = [] }) => {
    console.log('Latest companies in component:', companies);

    useEffect(() => {
        if (companies && companies.length > 0) { 
            if (typeof window !== 'undefined' && window.$ && window.jQuery) {
                if ($('.services-carousel').hasClass('owl-loaded')) {
                    $('.services-carousel').trigger('destroy.owl.carousel');
                    $('.services-carousel').find('.owl-stage-outer').children().unwrap();
                    $('.services-carousel').removeClass("owl-loaded");
                }
                
                $('.services-carousel').owlCarousel({
                    loop: true,
                    margin: 10,
                    nav: true,
                    responsive: {
                        0: { items: 1 },
                        600: { items: 2 },
                        1000: { items: 3 }
                    }
                });
            }
        }
    }, [companies]);

    if (!companies || companies.length === 0) {
        return null;
    }

    return (
        <div className="services-area carousel-shadow bg-cover bg-gray default-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="site-heading text-center">
                            <h4>10 Latest</h4>
                            <h2>Latest Registered Companies</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="services-items services-carousel owl-carousel owl-theme text-center">
                    {companies.map((business, id) => (
                        <div className="item" key={id}>
                            <div className="icon">
                                <i className="fa-regular fa-building"></i>
                            </div>
                            <div className="info">
                                <h4>{business.business_name}</h4>
                                <p>
                                    <strong>Municipality:</strong> {business.municipality}<br />
                                    <strong>Registration Date:</strong> {business.registration_date}<br />
                                    <strong>Status:</strong> {business.status_in_arbk}<br />
                                    <strong>Type:</strong> {business.business_type}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Fixed Shape Bottom */}
            <div className="fixed-shape-bottom">
                <img src="/path/to/your/shape-image.svg" alt="Shape" /> {/* Update the path accordingly */}
            </div>
        </div>
    );
};

export default LatestRegisteredCompanies;
