import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faVideoCamera, faChartLine, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function WorkProcess() {
  const processes = [
    { icon: faCheck, number: "01", title: "Choose a Service", description: "Arose mr rapid in so vexed words. Gay welcome led add lasting chiefly say looking better." },
    { icon: faVideoCamera, number: "02", title: "Request a Meeting", description: "Arose mr rapid in so vexed words. Gay welcome led add lasting chiefly say looking better." },
    { icon: faChartLine, number: "03", title: "Receive Custom Plan", description: "Arose mr rapid in so vexed words. Gay welcome led add lasting chiefly say looking better." },
    { icon: faQuestionCircle, number: "04", title: "Let's Make it Happen", description: "Arose mr rapid in so vexed words. Gay welcome led add lasting chiefly say looking better." }
  ];

  return (
    <div className="work-process-area overflow-hidden default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h4>Process</h4>
              <h2>How we works</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container-full">
        <div className="work-pro-items">
          <div className="row">
            {processes.map((process, index) => (
              <div key={index} className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="item-inner">
                    <div className="icon">
                      <FontAwesomeIcon icon={process.icon} />
                      <span>{process.number}</span>
                    </div>
                    <h5>{process.title}</h5>
                    <p>{process.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkProcess;