import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faGlobe, faCloud, faSave as faBackup} from '@fortawesome/free-solid-svg-icons';

function Features() {
  const features = [
    { icon: faCog, title: "Your Business", description: "Astonished set expression solicitude way admiration" },
    { icon: faGlobe, title: "Your Business", description: "Astonished set expression solicitude way admiration" },
    { icon: faCloud, title: "Your Business", description: "Astonished set expression solicitude way admiration" },
    { icon: faBackup, title: "Your Business", description: "Astonished set expression solicitude way admiration" }
  ];

  return (
    <div className="features-area overflow-hidden bg-gray default-padding">
      <div className="fixed-shape shape left bottom">
        <img src="/images/3.png" alt="Shape" />
      </div>
      <div className="container">
        <div className="row align-center">
          <div className="col-lg-5 why-us">
            <h5>why choose us</h5>
            <h2>Custom Solutions for Your Business</h2>
            <p>
              Carried nothing on am warrant towards. Polite in of in oh needed itself silent course. Assistance travelling so especially do prosperous appearance mr no celebrated. Wanted easily in my called formed suffer. Songs hoped sense as taken ye mirth at. Believe fat how six drawing pursuit minutes far. Same do seen head am part it dear open to Whatever.
            </p>
          </div>
          <div className="col-lg-7 features-box text-center">
            <div className="row">
              {[0, 1].map((rowIndex) => (
                <div key={rowIndex} className="col-lg-6 col-md-6 item-grid">
                  {features.slice(rowIndex * 2, (rowIndex + 1) * 2).map((feature, index) => (
                    <div key={index} className="item">
                      <FontAwesomeIcon icon={feature.icon} />
                      <h5><a href="#">{feature.title}</a></h5>
                      <p>{feature.description}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;