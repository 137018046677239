import React, { createContext, useState, useContext, useEffect } from 'react';
import authAPI from '../api/auth';
import api, { setAuthToken } from '../api/index';
import { useModal } from './ModalContext';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [authStateChanged, setAuthStateChanged] = useState(Date.now());
    const { openLoginModal } = useModal();

    // Utility function to update auth state
    const updateAuthState = (newState) => {
        setIsAuthenticated(newState);
        setAuthStateChanged(Date.now());
    };

    // Check authentication status on mount
    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            setAuthToken(token);
        }
        checkAuth();

        // Add event listener for auth:required events
        const handleAuthRequired = () => {
            openLoginModal();
        };
        window.addEventListener('auth:required', handleAuthRequired);
        
        return () => {
            window.removeEventListener('auth:required', handleAuthRequired);
        };
    }, [openLoginModal]);

    const checkAuth = async () => {
        try {
            const token = localStorage.getItem('access_token');
            if (!token) {
                throw new Error('No access token');
            }

            const userData = await authAPI.getUserDetails();
            setUser(userData);
            updateAuthState(true);
        } catch (error) {
            console.error('Auth check failed:', error);
            await logout();
        } finally {
            setLoading(false);
        }
    };

    const login = async (email, password) => {
        try {
            const response = await authAPI.login(email, password);
            if (response && response.access) {
                await checkAuth(); // This will set user data and authentication state
                return true;
            }
            return false;
        } catch (error) {
            console.error('Login error:', error);
            return false;
        }
    };

    const logout = async () => {
        try {
            await authAPI.logout();
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            setAuthToken(null);
            setUser(null);
            updateAuthState(false);
        }
    };

    const requireAuth = () => {
        if (!isAuthenticated) {
            openLoginModal();
            return false;
        }
        return true;
    };

    const value = {
        isAuthenticated,
        user,
        loading,
        login,
        logout,
        requireAuth,
        authStateChanged
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
