import React from 'react';

function Footer() {
  return (
    <footer className="bg-dark text-light">
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>Copyright © 2022. All rights reserved. <a href="#"> KSECO</a></p>
            </div>
            <div className="col-md-6 text-right link">
              <ul>
                <li><a href="#">Terms</a></li>
                <li><a href="#">Privacy</a></li>
                <li><a href="#">Support</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
